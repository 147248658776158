<template>
  <div class="text-right">
    <small class="mb-1">
      التفاسير العربية
    </small>
    <div>
      <template v-for="tafsir in $root.arabicShortTafsirs">
          <!-- :class="{'arrow-bottom': isSelected(tafsir)}" -->
          <v-chip
            :color="isSelected(tafsir) ? `${$vuetify.theme.dark ? 'orange darken-4' : 'blue lighten-5'}` : `${$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'}`"
            class="py-2 px-3 me-2"
            :key="tafsir.file"
            @click="syncTafsir(tafsir)"
            small
          >
            <small
              :class="{
                'primary--text': isSelected(tafsir) && !$vuetify.theme.dark,
                'thirdly--text': !isSelected(tafsir) && !$vuetify.theme.dark,
              }"
            >
              {{ tafsir.text }}
            </small>
          </v-chip>
      </template>
    </div>

    <v-slide-y-transition>
      <v-card v-if="selectedTafsir != null" outlined class="mt-4 arabic_tafsir">
        <v-card-text class="text-subtitle-1" v-if="tafsirText" v-html="tafsirText"></v-card-text>
        <v-skeleton-loader v-else type="paragraph"></v-skeleton-loader>
      </v-card>
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {
  props: ["suraId", "ayaId"],

  data() {
    return {
      selectedTafsir: null,
      tafsirText: "",
    };
  },

  methods: {
    isSelected(tafsir) {
      return (
        this.selectedTafsir !== null && this.selectedTafsir.file == tafsir.file
      );
    },

    syncTafsir(tafsir) {
      // clear the selection
      if (
        this.selectedTafsir !== null &&
        this.selectedTafsir.file == tafsir.file
      ) {
        this.selectedTafsir = null;
        return;
      }

      this.selectedTafsir = tafsir;
    },

    textColor(tafsir) {
      if (this.isSelected(tafsir)) {
        return "#000";
      }

      return "#fff";
    },
  },

  watch: {
    selectedTafsir(newVal) {
      if (newVal == null) {
        return;
      }
      let self = this;
      this.$service.loadTafsir(newVal, this.suraId, this.ayaId, (text) => {
        self.tafsirText = text;
      });
    },
  },
};
</script>

<style scoped>
.arabic_tafsir {
  direction: rtl;
  text-align: right;
}
</style>
