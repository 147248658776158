<template>
	<div class="text-center">
		<v-dialog v-model="dialog" scrollable width="550" persistent>
			<v-card class="rounded-lg custom-card-shadow wide">
				<v-card-title class="pt-4">
					<v-icon class="mr-2">mdi-download</v-icon>
					{{$t(`${message}_trans`)}}
				</v-card-title>
				
				<v-divider></v-divider>
				
				<v-card-text class="pt-3">
					{{ $t(`${message}_translation_dialog_message`) }}
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="px-10 rounded-sm border-sm" outlined color="primary" @click="onCancel">
						{{ $t("Cancel") }}
					</v-btn>
					<v-btn class="rounded-sm px-10" color="primary" variant="tonal" @click="submit" :disabled="loading" :loading="loading">
						<span> {{ message?.toUpperCase() }} </span>
					 </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: ["showDialog", "message", "action", "onCancel"],
	data() {
		return {
			loading: false,
		};
	},	

	computed: {
		dialog: {
			set(val) {
				this.$emit("update:showDialog", val);
			},
			get() {
				return this.showDialog;
			},
		},
	},

	methods: {
		async submit() {
			// change button text to loading
			this.loading = true;
			// call action
			if (typeof this.action === "function") {
				await this.action();
			}
			// close dialog
			this.loading = false;
			this.dialog = false;
		},
	},
};
</script>
