<template>
  <v-card class="mb-8 custom-card-shadow rounded-lg">
    <template v-if="(showQuran || forceShowQuranAya) && aya.index == 1 && aya.sura != 1 && aya.sura != 9">
      <v-card-title class="aya-text text-center justify-center">
        بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيمِ
      </v-card-title>
      <!-- TODO: Add some divider here -->
    </template>
    <v-card-title v-if="(showQuran || forceShowQuranAya)" class="d-block px-0 py-0 align-start">
        <v-chip
          color="secondary--text bg lighten-4"
          x-small
        >
          {{ $t("aya") }}: {{ aya.index }}
        </v-chip>
      <div
        class="px-4 aya-text text-center d-flex justify-center"
        align-self-center
      >
        {{ aya.arabic_text }}
      </div>
      
    </v-card-title>

    <v-card-text class="pt-2 pb-3">
      <div class="d-flex align-center mb-2" style="gap: 0.7em;">
        <hr class="flex-grow-1" style="opacity: 17%;" />
        <v-chip
          tile
          flat
          class="transparent px-0"
          :disabled="aya.loadingAudio"
          @click="$root.toggleAudio(aya)"
        >
            <v-icon large color="primary">
              {{ aya.playingAudio ? "mdi-pause-circle" : "mdi-play-circle" }}
            </v-icon>
        </v-chip>
        </div>
      <div
        class="text-center"
        v-show="!showQuran && !forceShowQuranAya"
      >
        <v-chip
          color="bg lighten-3"
          x-small
          class="mx-2"
          @click="forceShowQuranAya = true"
          >{{$t("show_aya")}}</v-chip>
      </div>

      <div v-show="showTrans || forceShowTransAya">
        
        <div class="fs-xs thirdly--text">
          <small>
          1-
          {{ $t("translation_databases." + currTrans) }}
          </small>
          <v-btn
            v-if="otherTranslations != null"
            text
            small
            class="primary--text"
            @click="$root.showOtherTranslationsDialog = true; 
                    $root.otherTrans = currTrans;"
          >
          
            <v-icon size="18" class="me-2">mdi-plus</v-icon>
            {{$t("view_other_transes")}}
          </v-btn>
        </div>
        <div>
          <div
            dir="auto"
            class="fs-sm my-2 font-weight-simibold secondary--text"
          >
            <span class="trans-text">{{ aya.translation }}</span>
            <v-btn
              color="primary"
              text
              small
              fab
              @click="toggleBookmark"
            > 
              <v-icon color="primary">
                {{ 
                  isBookmarked ? 'mdi-bookmark' : 'mdi-bookmark-outline'
                }}
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              text
              small
              fab
              @click="
                $root.noteSura = aya.sura;
                $root.noteAya = aya.index;
                $root.noteTrans = currTrans;
                $root.showNoteDialog = true;
              "
            >
              <v-icon color="primary">
                mdi-comment-edit-outline
              </v-icon>
            </v-btn>
            <v-btn color="primary" text small fab  @click="triggerShare">
              <v-icon color="primary">
                mdi-share-outline
              </v-icon>
            </v-btn>
          </div>
        </div>

        <div class="body-1" v-if="aya.footnotes">
          <div dir="auto" class="thirdly--text fs-xs font-weight-simibold">
            <span class="trans-footnotes">{{ aya.footnotes }}</span>
            
            
          </div>
        </div>

        <div
          v-for="(otherTrans, otherIndex) in otherTranslations"
          class="py-0 body-1"
          :key="`othertrans-${otherIndex}-${otherTrans.key}`"
        >
          <div class="fs-xs thirdly--text">
            <small>
            {{ otherIndex + 2 }}-
            {{ otherTrans.title }}
            </small>
          </div>
          <div dir="auto"
            class="fs-sm my-2 font-weight-simibold secondary--text">
            <span class="trans-text">{{ otherTrans.translation }}</span>
              <v-btn
                color="primary"
                text
                small
                fab
                @click="
                  $root.noteSura = aya.sura;
                  $root.noteAya = aya.index;
                  $root.noteTrans = otherTrans.key;
                  $root.showNoteDialog = true;
                "
              >
                <v-icon color="primary">
                  mdi-comment-edit-outline
                </v-icon>
              </v-btn>
          </div>
          <div v-if="otherTrans.footnotes">
            <div dir="auto" class="thirdly--text fs-xs font-weight-simibold">
              <span class="trans-footnotes">{{ otherTrans.footnotes }}</span>
                <v-btn
                  color="primary"
                  text
                  small
                  fab
                  @click="
                    $root.noteSura = aya.sura;
                    $root.noteAya = aya.index;
                    $root.noteTrans = otherTrans.key;
                    $root.showNoteDialog = true;
                  "
                >
                  <v-icon color="primary">
                    mdi-comment-edit-outline
                  </v-icon>
                </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!showTrans && !forceShowTransAya">
        <v-chip
          color="bg lighten-3"
          x-small
          @click="forceShowTransAya = true"
        >
          <span class="thirdly--text">
            {{ $t("translation_databases." + currTrans) }}
          </span>
        </v-chip>
      </div>
      
      <ArabicShortTafsir
          class="mt-3"
          :suraId="aya.sura"
          :ayaId="aya.index"
      ></ArabicShortTafsir>
    </v-card-text>
    <!-- go to aya  button -->
    <v-card-actions class="flex-wrap pb-3" v-if="showLinkToSura">
        <v-btn
          elevation="0"
          rounded
          class="flex-grow-1"
          color="bg lighten-4"
          @click="goToAyaInSura" 
        >
        {{$t("show_aya")}} 
          <v-icon small color="primary" class="ms-2">{{
            $vuetify.rtl ? "mdi-arrow-left" : "mdi-arrow-right"
          }}</v-icon>
        </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import ArabicShortTafsir from "./ArabicShortTafsir.vue";
import ShareDialog from "./ShareDialog.vue";

export default {
  props: [
    "aya",
    "currTrans",
    "hideNewTabIcon",
    "shouldShowSura",
    "showLinkToSura",
    "otherTranslations",
    "showQuran",
    "showTrans",
    'isBookmarked',
  ],

  components: {
    ArabicShortTafsir,
    ShareDialog
  },

  data() {
    return {
      shareUrl: "",
      showNoteDialog: false,
      forceShowQuranAya: false,
      forceShowTransAya: false,
    };
  },

  created() {
    this.$root.isAyaShown = true;
  },
  destroyed() {
    this.$root.isAyaShown = false;
  },

  mounted() {
    this.loadShareData();
  },

  methods: {
    shareContent(aya) { 
      const data =  { 
        arabic: aya.arabic_text,
        translation: aya.translation,
        footnotes: aya.footnotes,
        sura: this.suraName,
        translationName: this.$t("translation_databases." + this.currTrans),
      }

      this.$service.shareAsImage(data);
    },
    share(aya) {
      let text = aya.translation;
      if (aya.footnotes) {
        text += "\n------\n" + aya.footnotes;
      }
      text +=
        "\n\n" +
        "https://quranenc.com/" +
        this.$root.locale +
        "/browse/" +
        this.$route.query.trans +
        "/" +
        aya.sura +
        "#" +
        aya.index;
      this.$service.share(text);
    },
    loadShareData() {
      this.shareUrl = window.location.origin + "/aya/" + this.aya.id;
    },

    goToAyaInSura() {
      this.$root.nuxt.showLoading = true;
      this.$router.push({
        path: "/sura/" + this.aya.sura,
        query: { trans: this.currTrans, lang: this.$root.locale, index: this.aya.index },
      });
    },

    async toggleBookmark() {
      // emit event to parent with aya index and sura id, translation
      this.$emit("toggleBookmark");
    },

    triggerShare() {
      this.$emit("shareAyaFn");
    },

  },

  computed: {
    suraName() {
      if (!this.aya) {
        return "";
      }
      return this.$root.QuranData["Sura"][this.aya.sura][4]
    },

    displayOpenIcon() {
      return (
        typeof this.fromSingleRangePage == "undefined" ||
        !this.fromSingleRangePage
      );
    },
  },
  watch: {},
};
</script>

<style scoped>
.other_translations {
  float: left;
}
</style>
