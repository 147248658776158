<template>
  <v-app id="inspire">
    <v-navigation-drawer
      temporary
      :color="$vuetify.theme.dark ? '#030f22' : 'primary'"
      dark
      v-model="drawer"
      width="300"
      app
      :right="$vuetify.rtl"
    >
      <SideMenu></SideMenu>
    </v-navigation-drawer>
    <v-app-bar app tile :color="$vuetify.theme.dark ? 'grey darken-4' : 'white'" elevation="12">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="primary--text"
      ></v-app-bar-nav-icon>
      <v-btn
        v-if="showBackIcon"
        icon
        class="me-3"
        color="primary"
        @click="$root.goBack()"
      >
        <v-icon>{{ backIcon }}</v-icon>
      </v-btn>
      <v-toolbar-title class="primary--text font-weight-medium fs-md">
        <v-avatar
          @click="$root.goHome()"
          v-ripple="{ class: 'primary--text' }"
          size="50"
          class="cursor-pointer"
        >
          <img src="./assets/app_logo.png" />
        </v-avatar>
        <span class="fs-xs ms-3">
          {{ $t("title") }}
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="languagesDialog = !languagesDialog">
        <v-icon>mdi-earth</v-icon>
      </v-btn>

      <v-btn icon @click="$root.toggleDark()">
        <v-icon>mdi-brightness-6</v-icon>
      </v-btn>
  
      <v-menu top :offset-y="true" v-if="showFontControl">
          <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-format-size</v-icon>
              </v-btn>
          </template>
          <v-list dense>
              <v-list-item>
                  <v-list-item-title link>
                    <v-btn block @click.stop="fontSize(true)">A+</v-btn>
                  </v-list-item-title>
              </v-list-item>
              <v-list-item>
                  <v-list-item-title link>
                    <v-btn block @click.stop="fontSize()">A-</v-btn>
                  </v-list-item-title>
              </v-list-item>         
          </v-list>
      </v-menu> 

      <v-btn icon @click="showSearchDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <LanguageSelector
        :languagesDialog.sync="languagesDialog"
      ></LanguageSelector>
    </v-app-bar>
    <v-main>
      <v-container class="pt-6">
        <router-view></router-view>
      </v-container>
    </v-main>
    <audio ref="audioTag" autoplay></audio>
    <SearchingDialog
      :showSearchDialog.sync="showSearchDialog"
    ></SearchingDialog>
    
    <NoteDialog
      :showDialog.sync="$root.showNoteDialog"
      :sura="$root.noteSura"
      :aya="$root.noteAya"
      :trans="$root.noteTrans"
      :locale="$root.locale"
    ></NoteDialog>
    <OtherTranslationsDialog
      :showOtherTranslationsDialog.sync="$root.showOtherTranslationsDialog"
      :currTrans="$root.otherTrans"
    ></OtherTranslationsDialog>    
    <!-- global snackbar -->
    <v-snackbar
      v-model="$root.snackbar.show"
      :color="$root.snackbar.color"
      :timeout="$root.snackbar.timeout"
      :top="$root.snackbar.top"
      :bottom="$root.snackbar.bottom"
      :right="$root.snackbar.right"
      :left="$root.snackbar.left"
    >
      {{ $root.snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="$root.snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
 </v-app>
</template>

<script>
import SideMenu from "./components/SideMenu.vue";

import LanguageSelector from "./components/LanguageSelector.vue";
import SearchingDialog from "./components/SearchingDialog.vue";
import { EventBus } from "./event-bus.js";
import OtherTranslationsDialog from "./components/OtherTranslationsDialog.vue";
import NoteDialog from "./components/NoteDialog.vue";

export default {
  components: {
    SideMenu,
    NoteDialog,
    LanguageSelector,
    SearchingDialog,
    OtherTranslationsDialog,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    sowar: [],
    showSearchDialog: false,
    showSearchRes: false,
    languagesDialog: false,
  }),
  props: {
    source: String,
  },
  created() {},
  mounted() {
    if (this.$root.firstRun) {
      setTimeout(() => {
        this.languagesDialog = true;
      }, 200);
    }

   window.CapacitorApp?.addListener("backButton", () => {
      if (this.$route.name == "home" && !this.$route.query.d) {
        navigator.app.exitApp();
      } else {
        this.$router.back();
      }
    });

    this.setAudioPlayer(this);

    this.setTheme(this.$vuetify.theme.dark);
  },

  methods: {

   fontSize(increase=false){
      let root = document?.querySelector(':root');
      let computedStyle = getComputedStyle(root);
      let ayaFontSize = +(
       window.localStorage.getItem("ayaFontSize") || computedStyle.getPropertyValue('--aya-font-size'));
      let transTextSize = +(
        window.localStorage.getItem("transTextSize") ||  
      computedStyle.getPropertyValue('--trans-text-size'));
      let surahTitleSize = +(
        window.localStorage.getItem("surahTitleSize") || computedStyle.getPropertyValue('--surah-title-size'));
      ayaFontSize = ayaFontSize + (increase ? 1 : -1);
      transTextSize = transTextSize + (increase ? 1 : -1);
      surahTitleSize = surahTitleSize + (increase ? 1 : -1);
      root.style.setProperty('--aya-font-size', ayaFontSize);
      root.style.setProperty('--trans-text-size' , transTextSize);
      root.style.setProperty('--surah-title-size', surahTitleSize);
      window.localStorage.setItem("ayaFontSize", ayaFontSize);
      window.localStorage.setItem("transTextSize", transTextSize);
      window.localStorage.setItem("surahTitleSize", surahTitleSize);
    },

    setAudioPlayer(app) {
      app.$root.toggleAudio = (aya) => {
        //console.log(aya);
        //console.log(app.$refs.audioTag);
        const signature = Math.floor(Math.random() * Math.floor(10000));
        app.signature = signature; // store current signature in the parent object to bypass old callbacks

        if (!aya.playingAudio) {
          if (app.prevAya) {
            app.$set(app.prevAya, "playingAudio", false);
            app.$set(app.prevAya, "loadingAudio", false);
          }
          app.prevAya = aya;
          app.$set(aya, "playingAudio", true);
          app.$set(aya, "loadingAudio", true);

          let currentAya = aya.index;
          const basePath =
            "https://verse.mp3quran.net/arabic/ali_alhuthaify/64/"; // @Todo should be moved to global config;
          const fname =
            new String(aya.sura).padStart(3, "0") +
            new String(currentAya).padStart(3, "0") +
            ".mp3";
          app.$refs.audioTag.src = basePath + fname;
          app.$refs.audioTag.play();

          app.$refs.audioTag.addEventListener("canplay", () => {
            if (signature != app.signature) return;
            app.$refs.audioTag.play();
            aya.loadingAudio = false;
          });
          app.$refs.audioTag.addEventListener("ended", () => {
            if (signature != app.signature) return;
            if (!aya.playingAudio) return;
            aya.playingAudio = false; // will NOT take place in vue's reactivity system, but will affect current event loop
            app.$set(aya, "playingAudio", false);
            app.$set(aya, "loadingAudio", false);
          });
        } else {
          aya.playingAudio = false; // will NOT take place in vue's reactivity system, but will affect current event loop
          app.$set(aya, "playingAudio", false); // will take place in vue's reactivity system, but will NOT affect current event loop
          app.$set(aya, "loadingAudio", false);
          app.$refs.audioTag.pause();
          app.$refs.audioTag.currentTime = 0;
        }
      };
    },
    setTheme(v) {
      if (v === true) {
        document.documentElement.classList.add("theme--dark");
      } else {
        document.documentElement.classList.remove("theme--dark");
      }
    }
  },

  computed: {
    backIcon() {
      return this.$vuetify.rtl ? "mdi-arrow-right" : "mdi-arrow-left";
    },

    showBackIcon() {
      return this.$route.name != "home";
    },
    showSearchIcon() {
      return this.$route.name != "home";
    },
    showFontControl() {
      return this.$root.isAyaShown;
    },
  },
  watch: {
    drawer(val) {
      if (val) {
        let query = { ...this.$route.query, ...{ d: "1" } };
        this.$router
          .push({
            query: query,
          })
          .catch(() => {});
      } else {
        if (!this.$route.query.d) return;
        history.go(-1);
      }
    },
    "$route.query.d"(val) {
      if (!val) this.drawer = false;
    },
    "$vuetify.theme.dark"(v) {
      this.setTheme(v);
    }
  },
};
</script>

<style scoped>
.app_title {
  cursor: pointer;
}
</style>
