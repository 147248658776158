<template>
	<div class="py-4">
		<v-card class="mb-5">
			<v-card-title>
				{{ `${$t("bookmarks")} (${loading ? "..." : ayasContent.length})` }}
			</v-card-title>
		</v-card>

		<!-- add skeleton loaders in case of loading -->
		<!-- loop over ayaContent and display Aya component -->
		<template v-if="loading">
			<div class="my-4">
				<v-skeleton-loader v-for="n in 5" :key="n" class="custom-card-shadow mb-6"
					type="card"></v-skeleton-loader>
			</div>
		</template>
		<template v-else>
			<Aya v-for="(content, index) in ayasContent" :key="content.aya.id + '_' + content.translation"
				:id="'sura_aya_' + content.aya.id" :style="`animation-delay: ${index * 0.1}s`" :aya="content.aya"
				:currTrans="content.translation" :showLinkToSura="true" :otherTranslations="null" :showQuran="true"
				:showTrans="true"
				:isBookmarked="items[content.sura]?.[content.translation]?.[content.aya.index] ? true : false"
				@toggleBookmark="toggleBookmark(content.aya.index, content.sura, content.translation)">
			</Aya>
		</template>
	</div>
</template>

<script>
import Aya from "../components/Aya.vue";
export default {
	components: {
		Aya,
	},
	data: () => ({
		loading: false,
		items: [],
		ayasContent: [],
	}),
	async mounted() {
		await this.getItems();
		this.fetchAyasContent();
	},
	computed: {
		formattedBookmarks() {
			/* the current format looks like this: 
		  {
			"1": {
			  "english_hilali_khan": {
				"1": "2024-03-28T03:12:48.675Z"
			  }
			},
			"10": {
			  "english_saheeh": {
				"6": "2024-03-28T03:14:28.695Z"
			  }
			}
		  }
		*/
			// we want to flatten it to an array of objects sorted by date, each object should have the following properties:
			// sura, aya, translation, date
			// so the above example should be transformed to:
			// [
			//   { sura: 10, aya: 6, translation: "english_saheeh", date: "3/28/2024, 3:14:28 AM" },
			//   { sura: 1, aya: 1, translation: "english_hilali_khan", date: "3/28/2024, 3:12:48 AM" }
			// ]
			const bookmarks = this.items;
			const formattedBookmarks = [];
			for (const suraId in bookmarks) {
				const sura = bookmarks[suraId];
				for (const translation in sura) {
					const ayaIds = sura[translation];
					for (const ayaId in ayaIds) {
						const date = ayaIds[ayaId];
						formattedBookmarks.push({
							sura: parseInt(suraId),
							aya: parseInt(ayaId),
							translation,
							date: new Date(date).toLocaleString(),
						});
					}
				}
			}
			formattedBookmarks.sort(
				(a, b) => new Date(b.date) - new Date(a.date)
			);
			return formattedBookmarks;
		},
	},
	methods: {
		async getItems() {
			this.$set(this, "items", []);
			const items = await this.$service.getAllBookmarks();
			this.$set(this, "items", items);
		},
		fetchAyasContent() {
			this.$set(this, "loading", true);
			Promise.all(
				this.formattedBookmarks.map(async (bookmark) => {
					const aya = await this.$service.getSingleAyaByIndex(
						"arabic",
						bookmark.translation,
						bookmark.sura,
						bookmark.aya
					);
					return {
						...bookmark,
						aya,
					};
				})
			).then((ayas) => {
				this.ayasContent = ayas;
				this.loading = false;
			});
		},
		async toggleBookmark(aya, sura, translation) {
			try {
				const allBookmarks = await this.$service.toggleBookmark(
					aya,
					sura,
					translation,
					true
				);
				this.$set(this, "items", allBookmarks);
			} catch (error) {
				console.log("🚀 ~ toggleBookmark ~ error:", error);
			}
		},
	},
};
</script>
