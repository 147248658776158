import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/custom.css";
import vuetify from "./plugins/vuetify";
import i18n, { loadCurrentLocale } from "./i18n";
import QuranData from "./assets/QuranData.js";
import SuraTitles from "./assets/SuraTitles.js";
import RootHelpers from "./helpers/root.js";
import "@/assets/scss/index.scss";
import { VueMaskDirective } from "v-mask";
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';

// vue2-animate
require("vue2-animate/dist/vue2-animate.min.css");

// v-mask
Vue.directive("mask", VueMaskDirective);
if (process.env.VUE_APP_PLATFORM == "web") {
  require("./registerServiceWorker.js");
}
//const env = require('../env.json'); // use this as a workaround for electron limitations on extracting .env values

let isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") != -1;
if (isIOS) {
  let elem = document.createElement("link");
  elem.setAttribute("rel", "stylesheet");
  elem.setAttribute("type", "text/css");
  elem.setAttribute("href", "ios.css");
  document.getElementsByTagName("head")[0].appendChild(elem);
} else if (isAndroid) {
  let elem = document.createElement("link");
  elem.setAttribute("rel", "stylesheet");
  elem.setAttribute("type", "text/css");
  elem.setAttribute("href", "android.css");
  document.getElementsByTagName("head")[0].appendChild(elem);
}

// importing (or requiring) dynamic values (variables) is not working,
// so we may use these hard coded values and switch between manually
// or use the process.ENV, which is replaced as contant values by node during build time, but i think it has issues with electron
// it's not suggested to do both imports and assign the proper one to vue, as this will add much unused code and some of the imported code may not work on all platforms
// import ServiceProvider from './service-providers/WebApiServiceProvider.js';
// import ServiceProvider from './service-providers/MobilePlatformServiceProvider.js';
// import ServiceProvider from './service-providers/DesktopPlatformServiceProvider.js';

let ServiceProvider = require("./service-providers/" +
  process.env.VUE_APP_PLATFORM +
  "ServiceProvider.js").default;

Vue.config.productionTip = false;

Vue.prototype.$service = ServiceProvider;
Vue.prototype.$platform = process.env.VUE_APP_PLATFORM;
//env.platform == 'desktop'? DesktopPlatformServiceProvider: MobilePlatformServiceProvider;
//Vue.prototype.env = (variable, fallback = null) => {
//	return typeof env[variable] == 'undefined'? fallback: env[variable];
//};


Vue.config.errorHandler = function (err, vm, info) {
  console.error("Unhandled Vue Error:", err, info);
  // alert(JSON.stringify(err));
}

window.CapacitorApp = CapacitorApp;
window.Capacitor = Capacitor;


let AppObj = {
  vuetify,
  i18n,
  data: {
    nuxt: {},
    rtls: ["ar", "ur", "ps", "ug", "fa", "ku", "nko"],
    lang_rtls: [
      "arabic",
      "urdu",
      "pashto",
      "persian",
      "uyghur",
      "kurdish",
      "ankobambara",
    ],
    locale: "en",
    firstRun: false,
    snackbar: {
      show: false,
      text: "",
      color: "",
      timeout: 3000,
      top: false,
      bottom: true,
      right: false,
      left: false,
      actionText: "",
    },
    otherTranslations: [],
    arabicShortTafsirs: [],
    showOtherTranslationsDialog: null,
    showNoteDialog: null,
    noteTrans: "",
    otherTrans:"",
    noteSura: 0,
    noteAya: 0,
    localesList: [],
    translationsList: [],
    isAyaShown: false,
  },

  async created() {
    this.initUI();
    RootHelpers.initRootData(this);
    await Promise.all([this.initLanguage(), RootHelpers.initListsData(this)]);
  },

  methods: {
    // hook, will be filled in the child components
    goBack(){},
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      window.localStorage.setItem("dark", this.$vuetify.theme.dark ? "1" : ""); // localStorage store strings, so use empty string as bool false
    },

    initFonts() {
      try { 
      const ayaFontSize = window.localStorage.getItem("ayaFontSize");
      if (ayaFontSize && !isNaN(ayaFontSize)) {
        const _r = document.querySelector(":root");
        _r.style.setProperty("--aya-font-size", ayaFontSize);
      }
      const transTextSize = window.localStorage.getItem("transTextSize");
      if (transTextSize && !isNaN(transTextSize)) {
        const _r = document.querySelector(":root");
        _r.style.setProperty("--trans-text-size", transTextSize);
      }
      const surahTitleSize = window.localStorage.getItem("surahTitleSize");
      if (surahTitleSize && !isNaN(surahTitleSize)) {
        const _r = document.querySelector(":root");
        _r.style.setProperty("--surah-title-size", surahTitleSize);
      }}
      catch(e) {
        console.error("🚀 ~ initFonts ~ e", e)
      }
    },
    initUI() {
      const isDark = window.localStorage.getItem("dark");
      if (isDark) {
        this.$vuetify.theme.dark = true;
      }
      this.firstRun = !window.localStorage.getItem("runBefore");
      if (this.firstRun) {
        window.localStorage.setItem("runBefore", "1");
      }
      this.initFonts();
    },
    goHome() {
      this.$router.push({
        name: "home",
        query: {
          lang: this.locale,
        },
      });
    },
    
    async initLanguage() {
      try{ 
        // Get the language code from the query parameter, local storage, or default value
        let langCode = this.$route.query.lang || window.localStorage.getItem("current_language") || "en";


        // Set the RTL direction based on the language code
        this.$vuetify.rtl = this.rtls.indexOf(langCode) != -1;
        
        // Set the locale property to the language code
        this.locale = langCode;

        // Set the locale property in the i18n instance
        this.$i18n.locale = langCode;

        // Load the current locale messages asynchronously
        await loadCurrentLocale(this, langCode);
        
        // Save the language code to the local storage
        window.localStorage.setItem("current_language", langCode);

      } catch (e) {
        alert("Failed to load language: " + e.message)
        this.changeLanguage("en")
      }
    },

    async changeLanguage(langCode) {
      try { 
      const prev = this.locale;
      // Save the language code to the local storage
      window.localStorage.setItem("current_language", langCode);

      // Load the new locale messages asynchronously
      await loadCurrentLocale(this, langCode, prev)

      // Set the RTL direction based on the language code
      this.$vuetify.rtl = this.rtls.indexOf(langCode) != -1;

      // Set the locale property in the i18n instance
      this.$i18n.locale = langCode;
      
      // Set the locale property to the language code
      this.locale = langCode;

      } catch (e) {
        alert("Failed to load language: " + e.message)
        this.changeLanguage(prevLocale?? "en")
      }
    },

    showSnackbar(args)
    {
      this.snackbar = {
        show: true,
        text: args.text,
        color: args.color || "success",
        timeout: args.timeout || 3000,
        top: args.top || false,
        bottom: args.bottom || true,
        right: args.right || false,
        left: args.left || false,
      };  
    },
    hideSnackbar() {
      this.snackbar.show = false;
    },
  },

  computed: {
    QuranData() {
      let obj = QuranData;
      obj.Juz = QuranData.Juz.slice(1);
      obj.SuraTitles = SuraTitles[this.locale] || SuraTitles["en"];
      return obj;
    },
  },
  watch: {
    async "$route.query.lang"(val) {
      if (!val) return;
      await this.changeLanguage(val);
    },
  },
  router,
  store,
  render: (h) => h(App),
};
if (process.env.VUE_APP_PLATFORM == "mobile") {
  document.addEventListener("deviceready", onDeviceReady, false);
} else {  
  onDeviceReady();
}

function onDeviceReady() {
  new Vue(AppObj).$mount("#app");
  if (process.env.VUE_APP_PLATFORM == "mobile") {
    OneSignal.initialize(process.env.VUE_APP_ONESIGNAL_APP_ID);
    setTimeout(() => {
      OneSignal.Notifications.requestPermission(); // this is optional, we can ask for permission using i n-app dialog
    }, 7000);
  }
}
