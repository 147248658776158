// some helpers to interact with the application's root component
import AppConfigs from "../config/app.js";
//import env from '../../env.json';

const RootHelper = {
    initRootData(app) {
        app.$set(app.$root.nuxt,'curr_sura',0); // will be reactive, assigning to nuxt obj to avoid Vue warning, for other platforms add empty nuxt prop to the root instance
        // app.$set(app.$root.nuxt,'curr_range',0);
        app.$set(app.$root.nuxt,'showNoteDialog',0);
        
        app.$set(app.$root.nuxt,'showLoading',0);
        app.$set(app.$root.nuxt,'showSearchRes',0);
        

        app.$root.isoCodes = AppConfigs.isoCodes;
        app.$root.masterDatabase = AppConfigs[process.env.VUE_APP_PLATFORM + '_master_database'];
        app.$root.otherTranslations = [];
        app.$root.arabicShortTafsirs = AppConfigs[process.env.VUE_APP_PLATFORM + '_arabic_short_tafsirs'];
    },

    async initListsData(app) {
        [app.$root.localesList , app.$root.translationsList] = await Promise.all ([app.$service.loadLocalesList(), app.$service.loadTranslationsList()]);
        const[ updatedLocales,updatedTranslations]   =  await Promise.all ([app.$service.updateLocalesList(app.$root.localesList), app.$service.updateTranslationsList(app.$root.translationsList)]);
        updatedLocales && (app.$root.localesList = updatedLocales);
        updatedTranslations && (app.$root.translationsList = updatedTranslations);
        //this.setAudioPlayer(app);
    },
} 

export default RootHelper;