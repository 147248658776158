const axios = require('axios');
import QuranData from '../../../assets/QuranData.js';


export default {
    async getAyaTranslationbyId(translationKey, ayaId) {
        let params = this.id2aya(ayaId);
        return this.getAyaTranslationByIndexes(translationKey, params[0], params[1]);
    },

    async getAyaTranslationByIndexes(translationKey, suraNum, ayaIndex) {
        let url = `${process.env.VUE_APP_API_BASE_URL}v1/translation/aya/${translationKey}/${suraNum}/${ayaIndex}`;
        let response = await axios.get(url);
        let aya = response.data.result;

        return {
            id: aya.id,
            sura: aya.sura,
            index: aya.aya,
            arabic_text: aya.arabic_text,
            translation: aya.translation,
            footnotes: aya.footnotes
        };
    },

    async search(translationKey, searchText, page, perPage) {
        let url = `${process.env.VUE_APP_API_BASE_URL}v1/translation/search/${translationKey}?term=${searchText}`;
        let response = await axios.get(url);
        return response.data;
    },

    aya2id(sura, aya) {
        var id = 0;
        for (var i = 1; i < sura; i++) {
            id += QuranData.Sura[i][1];
        }
        id += aya;
        return id;
    },

    id2aya(id) {
        var i = 1;
        var temp_id = id;
        while (temp_id > 0) {
            temp_id -= QuranData.Sura[i++][1];
        }
        var sura = i - 1;
        var aya = QuranData.Sura[sura][1] + temp_id;
        return [sura, aya];
    }
};