<template>
  <v-dialog fullscreen scrollable v-model="dialog">
    <v-card>
      <v-card-title class="bg lighten-3">
        {{ $t("search_in") }}:
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-responsive class="mx-auto" max-width="500">
          <v-form>
            <v-select
              filled
              rounded
              class="rounded-sm"
              :items="searchingMethods"
              required
              v-model="method"
            ></v-select>
            <v-text-field
              filled
              rounded
              class="rounded-sm"
              :placeholder="$t('enter_search_phrase')"
              required
              @keypress.enter="fetchResults()"
              v-model="searchText"
            ></v-text-field>
            <v-responsive class="mx-auto" max-width="250">
              <v-btn
                :disabled="searchText.length < 3"
                block
                outlined
                x-large
                color="primary"
                class="border-sm"
                @click="fetchResults()"
              >
                {{ $t("search") }}
              </v-btn>
            </v-responsive>
          </v-form>
        </v-responsive>
        <v-responsive class="mx-auto px-6 py-6" max-width="1000">
          <template v-if="fetching">
            <div class="mt-4">
              <template v-for="n in 5">
                <v-skeleton-loader :key="n" class="custom-card-shadow mb-6" type="list-item-two-line"></v-skeleton-loader>
              </template>
            </div>
          </template>
          <template v-else>
            <div v-if="results.length > 0">
              <v-alert color="warning" border="left" class="mt-4 mb-6 custom-card-shadow" colored-border>
                {{$t("search_results")}}: {{ results.length }}
              </v-alert>
              <template v-for="(aya, index) in results">
                <v-card
                  :key="'search_result' + index"
                  class="my-4 custom-card-shadow"
                  @click="goToSura(aya)"
                >
                  <v-card-text>
                    <div :style="isMethodQuran?'font-size:20px;font-family:Hafs;line-height:160%':''" :class="isMethodRtl?'text-right':'text-left'"
                      :inner-html.prop="
                        attachSura(aya)
                          | highlight(searchQuery, $vuetify.theme.dark)
                      "
                    ></div>
                  </v-card-text>
                </v-card>
              </template>
            </div>
            <div v-else-if="searchQuery.length > 0">
              <v-card outlined class="mt-4">
                <v-card-text class="text-center">
                  {{$t("search_results")}}: 0
                </v-card-text>
              </v-card>
            </div>
          </template>
        </v-responsive>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["showSearchDialog"],

  filters: {
    highlight(text, query, isDark = false) {
      if (query) {
        const spanClass = isDark ? "orange darken-4" : "yellow lighten-1";
        const regex = new RegExp(query, "g");
        const replaced = text.replace(
          regex,
          `<span class="${spanClass} text--primary">${query}</span>`
        );
        return replaced;
      } else {
        return text;
      }
    },
  },

  data() {
    return {
      searchingMethods: [],
      searchText: "",
      searchQuery: "",
      method: "",
      usedMethod: "",
      results: [],
      fetching: false,
    };
  },

  mounted() {
    setTimeout(() => {
      //this.initSearchMethods();
    }, 400);
  },

  methods: {
    initSearchMethods() {
      this.searchingMethods = [];
      let arMethod = {
        text: "Quran - نص القرآن الكريم",
        value: {"key":"arabic","language_iso_code":"ar","file":"arabic"},
      };
      this.searchingMethods.push(arMethod);
      if(! this.method) {
        this.method = arMethod.value;
      }
      //console.log(this.$root.translationsList);
      let transes = this.$root.translationsList,q=this.$route.query.trans,l=this.$i18n.locale;
      if(l != 'ar' && l != 'en'){
        let currLang = transes.filter((o) => o.language_iso_code==l)
        transes = transes.filter((o) => o.language_iso_code!=l);
        console.log(currLang);
        transes = [...currLang,...transes];
      }
      if(q){
        let currActive = transes.find((o) => o.key==q)
        let idx = transes.indexOf(currActive);
        transes.splice(idx,1);
        transes.unshift(currActive);
      }
      
      for (let i in transes) {
        let trans = transes[i];
        //if (trans.key != this.$route.query.trans) continue;
        this.searchingMethods.push({
          text: this.$t("translation_databases." + trans.key),
          value: trans,
        });
      }
    },

    resetResults() {
      this.results = [];
      this.fetching = false;
    },
    attachSura(aya) {
      if (this.method.file == 'arabic') {
        return (
          aya.text +
          " [" +
          this.$root.QuranData["Sura"][aya.sura][4] +
          ":" +
          aya.index +
          "]"
        );
      } else {
        return aya.text + " [" + aya.sura + ":" + aya.index + "]";
      }
    },
    fetchResults() {
      if (this.searchText.length < 3) {
        return;
      }

      if (!this.searchText || !this.method) {
        alert(this.$t("enter_search_phrase"));
        return;
      }

      this.resetResults();
      
      this.usedMethod = this.method;

      this.fetching = true;
      if (this.method.file == "arabic") {
        this.searchArabicContent(this.searchText);
      } else {
        this.searchTranslations(this.searchText, this.method.file);
      }

      this.$set(this, "searchQuery", this.searchText);
    },

    searchArabicContent(text) {
      this.$service.searchArabicContent(
        text,
        1,
        (response) => {
          this.reflectSearchResponse(response);
        }
      );
    },

    searchTranslations(text, translationFile) {
      this.$service.searchTranslations(text, 1, translationFile, (response) => {
        this.reflectSearchResponse(response);
      });
    },

    reflectSearchResponse(response) {
      this.results = response.data;
      // this.numFound = response.total_results;
      // this.totalPages = response.total_pages;
      this.fetching = false;
    },

    goToSura(aya) {
      //this.$root.nuxt.showLoading = true;
      this.$router.push({
        name: "show_sura",
        params: {
          suraId: aya.sura,
        },
        query: {
          lang: this.$root.locale,
          index: aya.index,
          trans: (this.usedMethod.file == 'arabic')?'english_saheeh':this.usedMethod.file,
        },
      });
    },
  },

  computed: {
    dialog: {
      get() {
        return this.showSearchDialog;
      },

      set(newval) {
        this.$emit("update:showSearchDialog", newval);
      },
    },
    isMethodQuran() {
      return this.usedMethod.file == "arabic";
    },
    isMethodRtl() {
      if(this.usedMethod.file == 'arabic'){
        return true;
      }
      return (
        this.usedMethod &&
        typeof this.usedMethod.language_iso_code != "undefined" &&
        this.$root.rtls.indexOf(this.usedMethod.language_iso_code) != -1
      );
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.initSearchMethods();
        let query = { ...this.$route.query, ...{ d: "1" } };
        this.$router
          .push({
            query: query,
          })
          .catch(() => {});
      } else {
        if (!this.$route.query.d) return;
        history.go(-1);
      }
    },
    "$route.query.d"(val) {
      if (!val) this.dialog = false;
    },

    
  },
};
</script>

<style scoped>
.force-rtl {
  direction: rtl !important;
  text-align: right !important;
}
</style>
