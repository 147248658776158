import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue';
import SuraTranslation from './components/sura/_suraId.vue'
import SurasNavigation from './views/SurasNavigation.vue';
import Bookmarks from './views/Bookmarks.vue';
Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/sura/nav',
      name: 'suras_nav',
      component: SurasNavigation,
    },
    {
      path: '/sura/:suraId',
      name: 'show_sura',
      component: SuraTranslation
    },
    {
      path: "/bookmarks",
      name: "bookmarks",
      component: Bookmarks,
    },
  ]
})
