<template>
  <div>
    <v-card max-width="450" class="text-center mx-auto mb-5">
      <v-card-title
        class="font-weight-medium justify-center primary--text fs-md"
      >
        <span class="fs-sm">{{ $t("title") }}</span>
      </v-card-title>
      <v-card-text class="fs-xs font-weight-regular">
        {{ $t("slogan") }}
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="align-center">
        <small>{{ $t("browse_transes") }}</small>
        <v-spacer></v-spacer>
        <v-text-field
          v-ripple="{ class: 'primary--text' }"
          hide-details
          :placeholder="$t('search')"
          outlined
          class="rounded-md mt-2"
          single-line
          dense
          prepend-inner-icon="mdi-magnify"
          style="max-width: 200px"
          v-model="filterText"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pt-7">
        <transition-group name="fadeDown" tag="div"> 
          <template
            v-for="(translations, langKey) in groupedTranses"
          >
          <v-chip color="bg lighten-4" small :key="'chip'+langKey">{{ isoCodesObj[langKey] || "...." }}</v-chip>  
            <template v-for="(trans) in translations">
              <v-card
                :key="trans.key"
                class="mb-5 custom-card-shadow rounded-lg lang_navigator"
                v-ripple="{ class: 'primary--text' }"
                @click="gotoTrans(trans)"
              >
                <v-card-title class="fs-sm d-block">
                  <div>
                  <v-tooltip bottom v-model="tooltips[trans.key]" :open-on-hover="!$vuetify.breakpoint.mobile">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        class="mx-2"
                        @click.stop="tooltips[trans.key] = !tooltips[trans.key]"
                      >
                        <v-icon small>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("translation_databases." + trans.key + "_desc") }}</span>
                  </v-tooltip>
                  </div>
                  
                  <span class="fs-xs grey--text text--darken-1">{{ $t("translation_databases." + trans.key) }}</span>

                  <v-spacer></v-spacer>
                  <div class="text-end">
                    <v-btn text small class="font-weight-regular" color="primary">
                      <v-icon small class="me-2">
                        mdi-eye-outline
                      </v-icon>
                      {{ $t("browse_trans") }}
                    </v-btn>
                    <v-btn
                      text
                      small
                      class="font-weight-regular"
                      :color="
                        isDownloaded(trans.key) ? 'success' : 'primary'
                      "
                      :loading="isLoading(trans.key)"
                      :disabled="
                        isLoading(trans.key) ||
                          isDownloaded(trans.key)
                      "
                      @click.stop="download(trans.key)"
                    >
                      <v-icon small class="me-2">
                        {{
                          isDownloaded(trans.key)
                            ? "mdi-check"
                            : "mdi-cloud-download-outline"
                        }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card-title>
              </v-card>
            </template>
          </template>
        </transition-group>

      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      bottom
      center
      color="error"
      :timeout="3000"
    >
      {{ snackbartext }}
      <v-btn icon @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadings: [],
      downloaded: [],
      filterText: "",
      showFilter: false,
      tooltips: {},
      snackbar: false,
      snackbartext: "",
    };
  },
  computed: {
    isoCodesObj(){
      let obj={}
      const isos = this.$root.isoCodes || {};
      //let _codes = [];
      for(let i in isos){
        obj[i] = isos[i]?.native;
      }
      return obj;
    },
    groupedTranses() {
      const translations = this.$root.translationsList;
      const searchQuery = this.filterText.toLowerCase();
      const filtered = translations.filter(
        (translation) =>
          !searchQuery ||
          this.$t(`translation_databases.${translation.key}`)
            .toLowerCase()
            .includes(searchQuery)
      );
      const grouped = {};
      for (let i = 0; i < filtered.length; i++) {
        const el = filtered[i];
        const code = this.isoCodesObj[el.language_iso_code]
          ? el.language_iso_code
          : "other";
        if (!grouped[code]) {
          grouped[code] = [];
        }
        grouped[code].push(el);
      }
      if(grouped.other){
        const others = { ...grouped.other };
        delete grouped.other;
        grouped.other = others;
      }
      
      // set current interface language translations as first
      let grouped_ordered = grouped;
      if(grouped[this.$i18n.locale]){
        let tmp = {};
        tmp[this.$i18n.locale] = grouped[this.$i18n.locale]; // hold the current language translations
        delete grouped[this.$i18n.locale];
        grouped_ordered = {...tmp, ...grouped};
      }
      return grouped_ordered;      
      
    },
    
  },
  created(){
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.downloaded = await this.getDownloadedList() ?? [];
  },
  methods: {
    async download(trans) {
      try { 
      if (this.loadings.indexOf(trans) != -1) return;

      this.loadings.push(trans);

      await this.$service.downloadTranslation(trans);

      this.downloaded.push(trans);

      } catch (error) {
        console.log("🚀 ~ download ~ error:", error)
        this.snackbar = true;
        this.snackbartext = this.$t(error?.message) || error?.message || this.$t("error") || "Error";
      }finally{
        this.loadings = this.loadings.filter((item) => item !== trans);
      }
    },
    isDownloaded(trans) {
      return this.downloaded && this.downloaded.indexOf(trans) != -1;
    },
    async getDownloadedList() {
      let downloaded = await this.$service.getDownloadedTranslations();
      return downloaded ?? [];
    },
    isLoading(trans) {
      return this.loadings.indexOf(trans) != -1;
    },
    gotoTrans(translation) {
      this.tooltips = {};
      this.$root.otherTranslations = [];
      this.$router.push({
        name: "suras_nav",
        query: {
          lang: this.$root.locale,
          trans: translation.key,
        },
      });
    }
  },
};
</script>

<style scoped>
.lang_navigator {
  cursor: pointer;
}
</style>
