<template>
  <div class="mb-6">
    <!-- <v-pagination v-model="ayaNum" :length="maxAya" circle>
		</v-pagination> -->

    <v-card tile flat>
      <v-card-subtitle class="px-1">
        <b>{{ $t("translation_databases." + currTrans) }}</b>
      </v-card-subtitle>
      <v-card class="mb-5 custom-card-shadow rounded-lg">
        <v-card-title class="d-flex justify-space-between">
          <v-btn class="py-4" x-small :disabled="currSura <= 1" @click="goPrevSura()"><v-icon small>mdi-arrow-{{
          $vuetify.rtl ? 'right' : 'left' }}</v-icon></v-btn>

          <div class="fs-sm text-center">
            <v-icon></v-icon> {{ suraName }}
            <v-btn @click="showPageSelectorDialog = true" v-if="currPage" small
              class="mt-2 mx-auto d-block fs-xs text-center">{{ this.$t('page_num') }} {{ currPage }} 
            </v-btn>
          </div>
          <v-btn class="py-4" x-small :disabled="currSura >= 114" @click="goNextSura()"><v-icon small>mdi-arrow-{{
          $vuetify.rtl ? 'left' : 'right' }}</v-icon></v-btn>

        </v-card-title>
      </v-card>
      <v-text-field v-ripple="{ class: 'primary--text' }" hide-details outlined
        :disabled="fetching || loadedAyas.length === 0" class="rounded-md" single-line dense
        :placeholder="$t('aya') + ' (1-' + maxAya + ')'" append-icon="mdi-menu-swap" v-mask="'###'" clearable
        :error="gotoAyaError" @keydown.enter="changeAya(ayaNum)" @click:append="changeAya(ayaNum)"
        v-model="ayaNum"></v-text-field>
      <v-card-text class="pt-7 px-0">
        <template v-if="fetching">
          <div>
            <v-card v-for="n in 3" :key="n" class="custom-card-shadow mb-8">
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <v-skeleton-loader type="chip"></v-skeleton-loader>
                  <v-skeleton-loader max-width="400" min-width="80" width="400" class="center-bones"
                    type="sentences"></v-skeleton-loader>
                  <v-skeleton-loader type="button"></v-skeleton-loader>
                </div>
                <div class="mt-8">
                  <v-skeleton-loader max-width="400" min-width="80" type="sentences"></v-skeleton-loader>
                </div>
                <div class="mt-8 d-flex">
                  <v-spacer></v-spacer>
                  <v-skeleton-loader max-width="400" min-width="80" width="400" dir="ltr"
                    type="sentences"></v-skeleton-loader>
                </div>
                <div class="mt-8 d-flex">
                  <v-skeleton-loader type="actions"></v-skeleton-loader>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </template>
        <div v-else>
          <v-row dense>
            <v-col>
              <v-btn-toggle class="mb-2" style="background:transparent" color="bg" mandatory v-model="showMode">
                <v-btn @click="showQuran = true; showTrans = true"><v-icon small>mdi-view-day</v-icon></v-btn>
                <v-btn @click="showQuran = false; showTrans = true"><v-icon small>mdi-view-day-outline</v-icon></v-btn>
                <v-btn @click="showQuran = true; showTrans = false"><v-icon
                    small>mdi-view-split-horizontal</v-icon></v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-btn block color="primary" class="mb-4" v-if="firstAyaInPage > 1"
            @click="goUp()"><v-icon>mdi-arrow-up</v-icon>{{
          `${this.$t('page')} ${this.currPage - 1}` }}</v-btn>
          <v-btn v-else-if="prevSuraName" block color="primary" class="mb-4" @click="goPrevSura()">
            <v-icon>mdi-arrow-{{ $vuetify.rtl ? 'right' : 'left' }}</v-icon>
            {{ prevSuraName }}
          </v-btn>
          <transition name="fade">
            <div :key="this.currSura">
              <Aya v-for="(aya, index) in loadedAyas" :key="currSura + '_' + aya.index + '_' + currTrans"
                :id="'sura_aya_' + aya.index" :style="`animation-delay: ${index * 0.1}s`" :aya="aya"
                :otherTranslations="getAyaOtherTranslations(aya.index)" :currTrans="currTrans" :showQuran="showQuran"
                :showTrans="showTrans" :isBookmarked="!!bookmarkedAyas[+(aya.index)]"
                @toggleBookmark="toggleBookmark(aya.index)"
                @shareAyaFn="shareAya = +aya.index; showShareDialog = true"
                ></Aya>
            </div>
          </transition>
          <v-btn block color="primary" class="mb-4" v-if="lastAyaInPage < ayas.length"
            @click="goMore()"><v-icon>mdi-arrow-down</v-icon>{{ `${this.$t('page')} ${this.currPage + 1}` }}</v-btn>
          <v-btn v-else-if="nextSuraName" block color="primary" class="mb-4" @click="goNextSura()">
            {{ nextSuraName }}
            <v-icon>mdi-arrow-{{ $vuetify.rtl ? 'left' : 'right' }}</v-icon>
          </v-btn>

        </div>
      </v-card-text>
    </v-card>
    <TranslationDownloadDialog :showDialog.sync="showDownloadDialog" :message="downloadDialogMessage"
      :action="downloadDialogAction" :onCancel="onCancelDownloadDialog" />
    <PageSelectorDialog :showDialog.sync="showPageSelectorDialog" @submit="navigateToPage" :currPage="currPage" />
    <ShareDialog
      :showDialog.sync="showShareDialog"
      :currAya="shareAya"
      :minAya="minShareAya"
      :maxAya="maxShareAya"
      @shareAsText="shareAsText"
      @copyAsText="copyAsText"
      @shareAsImage="shareAsImage"
    ></ShareDialog>
 </div>
</template>

<script>
import Aya from "../Aya.vue";
import { EventBus } from "../../event-bus.js";
import TranslationDownloadDialog from "../TranslationDownloadDialog.vue";
import PageSelectorDialog from "../PageSelectorDialog.vue";
import ShareDialog from "../ShareDialog.vue";

export default {
  components: {
    Aya,
    TranslationDownloadDialog,
    PageSelectorDialog,
    ShareDialog,
  },
  data() {
    return {
      ayaNum: null,
      ayas: [],
      otherTranslations: [],
      chunkLoadingInterval: 500,
      fetching: true,
      gotoAyaError: false,
      interval: 0,
      fromAya: 0,
      toAya: 0,
      chunkSize: 20, // int dividable by 2
      showQuran: true,
      showTrans: true,
      showMode: 0,
      showDownloadDialog: false,
      downloadDialogMessage: "",
      downloadDialogAction: this.downloadTrans,
      bookmarkedAyas: [],
      showPageSelectorDialog: false,
      showShareDialog: false,
      shareAya: 0,
    };
  },
  created() {
  },
  async mounted() {
    this.$root.goBack = () => {
      this.goSuraList();
    }
    window.scrollTo(0, 0);
    this.$root.nuxt.curr_sura = this.currSura;
    this.$root.nuxt.showSearchRes = false

    try {
      await this.loadAyasFromService();
      // after a couple of seconds, check if there is an update for the translation
      if (!this.downloadDialogMessage) setTimeout(async () => { await this.checkTransUpdate(); }, 3000);
    } catch (error) {
      alert(this.$t(error.message) || error.message);
    }

    EventBus.$on("other-translations-changed", () => {
      this.loadOtherTranslations();
    });

    this.loadOtherTranslations();

    await this.getSuraBookmarks();
  },

  methods: {
    
     getShareData (fromAya, toAya, arabicName = false) {
      const ayasData = this.ayas.slice(fromAya - 1, toAya);
      const shareUrl =  "https://quranenc.com/" +
        this.$root.locale +
        "/browse/" +
        this.$route.query.trans +
        "/" +
        this.currSura +
        "#" +
        fromAya;
      const suraName = arabicName ? this.$root.QuranData["Sura"][this.currSura][4] : this.suraName;

      return [ayasData, suraName, this.$t("translation_databases." + this.currTrans), shareUrl]
    },

    async shareAsText(fromAya, toAya) {
      this.$service.shareAsText(...this.getShareData(fromAya, toAya)).then(() => {
        this.showShareDialog = false;
        this.$root.showSnackbar({text: this.$t("shared_as_text"), color: "success"});
      }).catch((err) => {
        this.$root.showSnackbar({text: this.$t(err.message) || err.message || this.$t("error_sharing_as_text"), color: "error"});
      });    
    },
    
    async copyAsText(fromAya, toAya) {
      this.$service.shareAsText(...this.getShareData(fromAya, toAya), true).then(() => {
        this.showShareDialog = false;
        this.$root.showSnackbar({text: this.$t("copied_to_clipboard"), color: "success"});
      }).catch((err) => {
        this.$root.showSnackbar({text: this.$t(err.message) || err.message || this.$t("error_copying_to_clipboard"), color: "error"});
      });
    },
    
    async shareAsImage(fromAya, toAya) {
      this.$service.shareAsImage(...this.getShareData(fromAya, toAya, true)).then(() => {
        this.showShareDialog = false;
        this.$root.showSnackbar({text: this.$t("shared_as_image"), color: "success"});
      }).catch((err) => {
        this.$root.showSnackbar({text: this.$t(err.message) || err.message || this.$t("error_sharing_as_image"), color: "error"});
      });
    },
    goSuraList() {
      this.$router.push({
        name: "suras_nav",
        query: {
          lang: this.$root.locale,
          trans: this.currTrans,
        },
      });
    },
    goNextSura() {
      this.$router.push({
        name: "show_sura",
        params: {
          suraId: this.currSura + 1
        },
        query: {
          lang: this.$root.locale,
          trans: this.currTrans,
        },
      });
    },
    goPrevSura() {
      this.$router.push({
        name: "show_sura",
        params: {
          suraId: this.currSura - 1
        },
        query: {
          lang: this.$root.locale,
          trans: this.currTrans,
        },
      });
    },

    async loadAyasFromService() {
      try {
        const ayasFetched = await this.$service.getSuraContent(
          this.currTrans,
          this.currSura,
        )
        this.ayas = ayasFetched;
        this.fetching = false;
        this.gotoAya(this.$route.query.index);
      } catch (error) {
        console.log("🚀 ~ loadAyasFromService ~ error:", error.name)
        // if error is NoDbFoundError, then prompt user to download the db
        if (error.name == "NoDbError") {
          this.showDownloadDialog = true;
          this.downloadDialogMessage = "download";
          this.downloadDialogAction = this.downloadTrans;
        }
      }
    },

    async downloadTrans() {
      try {
        await this.$service.downloadTranslation(this.currTrans);
        this.showDownloadDialog = false;
        await this.loadAyasFromService();
      } catch (error) {
        alert(this.$t(error.message) || error.message || this.$t("error_downloading_translation" || "error_downloading_translation"));
      }
    },

    async checkTransUpdate() {
      try {
        const { updateAvailable } = await this.$service.checkTranslationUpdate(this.currTrans);
        if (updateAvailable) {
          this.showDownloadDialog = true;
          this.downloadDialogMessage = "update";
          this.downloadDialogAction = this.updateTrans;
        }
      } catch (error) {
        console.log("🚀 ~ updateTrans ~ error:", error)
      }
    },

    async updateTrans() {
      try {
        await this.$service.downloadTranslation(this.currTrans);
        this.showDownloadDialog = false;
        await this.loadAyasFromService();
      } catch (error) {
        this.showDownloadDialog = true;
        this.downloadDialogMessage = "update";
      }
    },

    onCancelDownloadDialog() {
      this.showDownloadDialog = false;
      // replace current route with home
      if (this.downloadDialogMessage == "download") {
        this.$router.replace({
          name: "home",
          query: this.$route.query,
        });
      }
    },

    loadOtherTranslations() {
      if (this.$root.otherTranslations.length <= 0) {
        this.otherTranslations = [];
        return;
      }
      this.$service.loadSuraOtherTranslations(
        this.currSura,
        this.$root.otherTranslations,
        this.currTrans,
        (response) => {
          this.otherTranslations = response;
          // console.log('showww', this.otherTranslations);
          // let test = this.getAyaOtherTranslations(1);
          // console.log(test)
        }
      );
    },

    getAyaOtherTranslations(ayaIndex) {
      let transes = [];

      for (let mainIndex in this.otherTranslations) {
        let obj = this.otherTranslations[mainIndex];
        for (let index in obj.translations) {
          if (obj.translations[index].aya != ayaIndex) {
            continue;
          }

          transes.push({
            key: obj.key,
            title: this.$t("translation_databases." + obj.key),
            translation: obj.translations[index].translation,
            footnotes: obj.translations[index].footnotes,
          });
        }
      }

      return transes;
    },

    gotoAya(ayaIndex) {
      ayaIndex = +ayaIndex ?? 1;
      const scrollToStart = this.$route.query.pageStart;
      const firstAyaInPage = this.$root.QuranData.Page[this.currPage][1];
      if (ayaIndex > 1) {
        setTimeout(() => {
          let ayaNavigator = "#sura_aya_" + (scrollToStart ? firstAyaInPage : ayaIndex);
          this.$vuetify.goTo(ayaNavigator);
        }, 500);
      }
    },
    goUp() {
      this.changeAya(this.firstAyaInPage - 1, true);
    },
    goMore() {
      this.changeAya(this.lastAyaInPage, true);
    },
    changeAya(ayaIndex, up = false) {
      ayaIndex = parseInt(ayaIndex);
      if (isNaN(ayaIndex) || ayaIndex < 1 || ayaIndex > this.ayas.length) {
        this.gotoAyaError = true;
        return;
      }
      this.gotoAyaError = false;

      this.$router.push({
        name: "show_sura",
        params: {
          suraId: this.$route.params.suraId
        },
        query: {
          lang: this.$root.locale,
          trans: this.currTrans,
          index: ayaIndex,
          pageStart: up
        },
      });
    },

    async getSuraBookmarks() {
      try {
        let bookmarks = await this.$service.getSuraBookmarks(this.currSura, this.currTrans);
        this.bookmarkedAyas = bookmarks;
      } catch (error) {
        console.log("🚀 ~ getSuraBookmarks ~ error:", error);
      }
    },

    async toggleBookmark(ayaIndex) {
      try {
        let bookmarks = await this.$service.toggleBookmark(ayaIndex, this.currSura, this.currTrans);
        this.bookmarkedAyas = bookmarks;
      } catch (error) {
        console.log("🚀 ~ toggleBookmark ~ error:", error);
      }
    },

    navigateToPage(page) {
      // get the surah and aya corresponding to the page
      const [sura, aya] = this.$root.QuranData.Page[page];
      this.$router.push({
        name: "show_sura",
        params: {
          suraId: sura
        },
        query: {
          lang: this.$root.locale,
          trans: this.currTrans,
          index: aya,
        },
      });
    },
  },

  computed: {
    suraName() {
      return this.$root.locale == "ar"
        ? this.$root.QuranData["Sura"][this.currSura][4]
        : this.$root.QuranData["SuraTitles"][this.currSura] +
        " - " +
        this.$root.QuranData["Sura"][this.currSura][4];
    },
    nextSuraName() {
      if (this.currSura >= 114) return "";
      return this.$root.locale == "ar"
        ? this.$root.QuranData["Sura"][this.currSura + 1][4]
        : this.$root.QuranData["SuraTitles"][this.currSura + 1] +
        " - " +
        this.$root.QuranData["Sura"][this.currSura + 1][4];
    },
    prevSuraName() {
      if (this.currSura <= 1) return "";
      return this.$root.locale == "ar"
        ? this.$root.QuranData["Sura"][this.currSura - 1][4]
        : this.$root.QuranData["SuraTitles"][this.currSura - 1] +
        " - " +
        this.$root.QuranData["Sura"][this.currSura - 1][4];
    },
    maxAya() {
      return this.$root.QuranData.Sura[this.currSura][1];
    },
    currTrans() {
      return this.$route.query.trans;
    },
    currSura() {
      return parseInt(this.$route.params.suraId);
    },
    currPage() {
      return this.$root.QuranData.findPage(this.currSura, this.$route.query.index ?? 1);
    },
    firstAyaInPage() {
      if (!this.$route.query.index) return 1;
      const [sura, aya] = this.$root.QuranData.Page[this.currPage];
      if (sura < this.currSura) return 1;
      return aya;
    },
    lastAyaInPage() {
      const [sura, aya] = this.$root.QuranData.Page[this.currPage + 1];
      if (sura > this.currSura) return this.maxAya + 1;
      return aya;
    },
    loadedAyas() {
      return this.ayas.slice(this.firstAyaInPage - 1, this.lastAyaInPage - 1);
    },
    minShareAya() {
      // first aya in the sura
      return 1;
    },
    maxShareAya() {
      // last aya in the sura
      return this.maxAya;
    },
  },

  destroyed() {
    EventBus.$off("other-translations-changed");
    EventBus.$off("locale-db-changed");
    EventBus.$off("databases-connected");

    clearInterval(this.interval);
  },

  watch: {
    "$route.query.index"(val, oldVal) {
      if (val == oldVal) return;
      this.gotoAya(val);
    },

    async "$route.params.suraId"(val, oldVal) {
      if (val == oldVal) return;
      window.scrollTo(0, 0);

      this.ayaNum = null;
      this.ayas = [];
      //this.loadedAyas = [];
      this.otherTranslations = [];
      this.$root.nuxt.showLoading = true;

      await this.loadAyasFromService();
      await this.getSuraBookmarks();
    },

    async "$route.query.trans"(val, oldVal) {
      if (val == oldVal) return;
      await this.getSuraBookmarks();
    },
  }
};
</script>
