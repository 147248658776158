<template>
    <div>
        <v-dialog v-model="dialog" scrollable max-width="400px">
            <v-card class="wide custom-card-shadow">
                <v-form @submit.prevent="submitShare">
                    <v-card-title>
                        {{ $t("share") }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pt-3">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field filled rounded class="rounded-sm" :min="this.minAya" :max="toAya"
                                    type="number" :label="$t('from_aya')" v-model="fromAya"
                                    :rules="[v => !!v || $t('required'), v => (v && v >= this.minAya && v <= toAya) || `${this.minAya} - ${this.toAya}`]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field filled rounded class="rounded-sm" :min="fromAya" :max="this.maxAya"
                                    type="number" :label="$t('to_aya')" v-model="toAya"
                                    :rules="[v => !!v, v => (v && v >= fromAya && v <= this.maxAya) || `${this.fromAya} - ${this.maxAya}`]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-radio-group v-model="shareType">
                            <v-radio value="copy" :label="this.$t('copy_text')"></v-radio>
                            <v-radio value="text"  :label="this.$t('share_text')"></v-radio>
                            <v-radio value="image" :label="this.$t('share_image')"></v-radio>
                        </v-radio-group>
                    </v-card-text>
                    <v-card-actions class="pb-4 px-md-14">
                        <v-spacer></v-spacer>
                        <v-btn large class="px-10 rounded-sm border-sm" outlined color="primary"
                            @click="dialog = false">
                            {{ $t("cancel") }}
                        </v-btn>
                        <v-btn color="primary" large class="rounded-sm px-10" type="submit" :ref="'submitShare'">
                            {{ $t("submit") }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ["showDialog", "currAya", "maxAya", "minAya"],
    data() {
        return {
            fromAya: this.currAya,
            toAya: this.currAya,
            shareType: "text",
        };
    },

    computed: {
        dialog: {
            get() {
                return this.showDialog;
            },
            set(val) {
                this.$emit("update:showDialog", val);
            },
        },
    },
    methods: {
        submitShare() {
            this.$refs.submitShare.loading = true;
            this.$refs.submitShare.disabled = true;
            if (this.shareType === "text") {
                this.$emit("shareAsText", this.fromAya, this.toAya);
            } else if (this.shareType === "copy") {
                this.$emit("copyAsText", this.fromAya, this.toAya);
            } else if (this.shareType === "image") {
                this.$emit("shareAsImage", this.fromAya, this.toAya);
            }
            // this.dialog = false;
        },
    },
    watch: {
        currAya(val) {
            this.fromAya = val;
            this.toAya = val;
        },
        dialog(val) {
            if (val) {
                let query = { ...this.$route.query, ...{ d: "1" } };
                this.$router.push({ query: query }).catch(() => { });
            } else {
                if (!this.$route.query.d) return;
                history.go(-1);
            }
        },
        "$route.query.d"(val) {
            if (!val) this.dialog = false;
        },
    },
};
</script>