<template>
  <div class="text-center">
    <v-dialog v-model="dialog" scrollable width="550">
      <v-card class="rounded-lg custom-card-shadow wide">
        <v-card-title >
          {{$t('lang')}}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
          <div class="d-block mt-2" style="width:100%">
            <v-text-field
            v-model="searchQuery"
            filled
            rounded
            class="rounded-none mb-2"
            hide-details
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('search')"
          ></v-text-field>
        </div>
        </v-card-title>
        
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(language, i) in filteredLangs"
              :key="'lang' + i"
              :class="`grey ${$vuetify.theme.dark ? 'darken-4' : 'lighten-5'} rounded-mdl mb-4`"
              @click="changeLanguage(language)"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">
                  {{ language.native }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon size="18" color="primary" class="rtl-flip">
                  mdi-arrow-right
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["languagesDialog"],

  data: () => ({
    searchQuery: "",
  }),

  methods: {
    changeLanguage(lang) {
      if (lang.iso_code == this.$route.query.lang) {
        this.dialog = false;
        return;
      }
      let query = { ...this.$route.query, ...{ lang: lang.iso_code } };
      delete query["d"];
      setTimeout(() => {
        this.$router.push({
          name: "home",
          query: query,
        });
      }, 200);
    },
  },

  computed: {
    dialog: {
      get() {
        return this.languagesDialog;
      },
      set(newval) {
        this.$emit("update:languagesDialog", newval);
      },
    },
    filteredLangs() {
      const langs = this.$root.localesList;
      const searchQuery = this.searchQuery.toLowerCase();
      const filteredLangs = langs.filter(
        (lang) =>
          !searchQuery ||
          lang.native.toLowerCase().includes(searchQuery)
      );
      return filteredLangs;
    },
  },
  watch: {
    dialog(val) { 
      if (val) {
        let query = { ...this.$route.query, ...{ d: "1" } };
        this.$router
          .push({
            query: query,
          })
          .catch(() => {});
      } else {
        if (!this.$route.query.d) return;
        history.go(-1);
      }
    },
    "$route.query.d"(val) {
      if (!val) this.dialog = false;
    },
  },
};
</script>
