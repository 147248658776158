const axios = require('axios');

export default {
    async fetchSuraContent(translationKey, suraNum, map = true) {
        let url = `${process.env.VUE_APP_API_BASE_URL}v1/translation/sura/${translationKey}/${suraNum}`;
        let response = await axios.get(url);
        let ayas = response.data.result;

        return map? ayas.map( (aya) => {
            return {
                id: aya.id,
                sura: aya.sura,
                index: aya.aya,
                arabic_text: aya.arabic_text,
                translation: aya.translation,
                footnotes: aya.footnotes,
            };
        } ): ayas;
    }
};