import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

const customColors = {
  primary: "#1284C0",
};

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  customVariables: ["@/assets/scss/vuetify_variables.scss"],
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        light: "#F9F9F9",
        primary: customColors.primary,
        anchor: customColors.primary,
        secondary: "#3C3C3C",
        "thirdly": "#5C5C5C",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#21cb88",
        warning: "#FFC107",
        "deep-purple": "#E408FF",
        cerise: "#FF0092",
        bg: colors.grey,
        type: "#000",
      },
      dark: {
        primary: customColors.primary,
        anchor: customColors.primary,
        secondary: "#b9b9b9",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#21cb88",
        warning: "#FFC107",
        "deep-purple": "#E408FF",
        cerise: "#FF0092",
        bg: colors.black,
        type: "#fff",
      },
    },
  },
});
