import { Preferences } from "@capacitor/preferences";
import AppConfigs from "../../config/app.js";

class PreferencesApi {
	/**
	 * Fetches the list of locales from the preferences.
	 * If the list is not available, it sets the default list and returns it.
	 * @returns {Promise<Array<string>>} The list of locales.
	 */
	static async fetchLocalesList() {
		let res = await Preferences.get({ key: "localesList" });
		if (!res?.value) {
			const defaultList = AppConfigs.languages;
			await Preferences.set({
				key: "localesList",
				value: JSON.stringify(defaultList),
			});
			return defaultList;
		}
		return JSON.parse(res.value);
	}

	/**
	 * Saves the list of locales to the preferences.
	 * @param {Array} list - The list of locales to be saved.
	 * @returns {Promise<void>} - A promise that resolves when the locales list is saved.
	 */
	static async saveLocalesList(list) {
		await Preferences.set({
			key: "localesList",
			value: JSON.stringify(list),
		});
	}

	/**
	 * Fetches the translations list from the preferences.
	 * If the translations list is not found, it sets the default list and returns it.
	 * @returns {Promise<Array>} The translations list.
	 */
	static async fetchTranslationsList() {
		let res = await Preferences.get({ key: "translationsList" });
		if (!res?.value) {
			const defaultList =
				AppConfigs[
					process.env.VUE_APP_PLATFORM + "_translation_databases"
				];
			await Preferences.set({
				key: "translationsList",
				value: JSON.stringify(defaultList),
			});
			return defaultList;
		}
		return JSON.parse(res.value);
	}

	/**
	 * Saves the translations list to the preferences.
	 * @param {Array} list - The translations list to be saved.
	 * @returns {Promise<void>} - A promise that resolves when the translations list is saved.
	 */
	static async saveTranslationsList(list) {
		await Preferences.set({
			key: "translationsList",
			value: JSON.stringify(list),
		});
	}

	/**
	 * Fetches the timestamp for a specific locale from the preferences.
	 * If no locale is provided, returns the entire list of locale timestamps.
	 * @param {string} [locale] - The locale for which to fetch the timestamp.
	 * @returns {number|Object} - The timestamp for the specified locale, or the entire list of locale timestamps.
	 */
	static async fetchLocaleTimestamp(locale=null) {
		let list = await Preferences.get ({key: "eachLocaleTimestamp"});	
		list = JSON.parse(list.value ?? "{}");
		let res = locale? list[locale] ?? 0 : list ?? {};
		return res
	}

	/**
	 * Saves the locale timestamp in the preferences.
	 * @param {string} locale - The locale to be modified.
	 * @param {number} timestamp - The timestamp to be saved.
	 * @returns {Object} - The updated list of locale timestamps.
	 */
	static async saveLocaleTimestamp(locale, timestamp) {
		const list = await PreferencesApi.fetchLocaleTimestamp();
		list[locale] = timestamp;
		await Preferences.set({
			key: "eachLocaleTimestamp",
			value: JSON.stringify(list),
		});

		return list; // in case we need to use it
	}

	/**
	 * Fetches the translation timestamp for a given ID.
	 * If no ID is provided, returns the entire list of translation timestamps.
	 *
	 * @param {string} [id] - The ID of the translation.
	 * @returns {Promise<number|Object>} - The translation timestamp for the given ID, or the entire list of translation timestamps.
	 */
	static async fetchTranslationTimestamp(id) {
		let list = await Preferences.get({ key: "eachTranslationTimestamp" });
		if (!list.value) {
			const defaultList = AppConfigs.bundled_translation_timestamp;
			await Preferences.set({
				key: "eachTranslationTimestamp",
				value: JSON.stringify(defaultList),
			});
			return defaultList;
		}
		list = JSON.parse(list.value ?? "{}");
		let res = id ? list[id] ?? 0 : list ?? {};
		return res;
	}

	/**
	 * Saves the translation timestamp for a given ID.
	 * @param {string} id - The ID of the translation.
	 * @param {number} timestamp - The timestamp to be saved.
	 * @returns {Object} - The updated list of translation timestamps.
	 */
	static async saveTranslationTimestamp(id, timestamp) {
		const list = await PreferencesApi.fetchTranslationTimestamp();
		list[id] = timestamp;
		await Preferences.set({
			key: "eachTranslationTimestamp",
			value: JSON.stringify(list),
		});

		return list; // in case we need to use it
	}

	/**
	 * Fetches the timestamp of the translations list from the preferences.
	 * @returns {Promise<number>} The timestamp of the translations list.
	 */
	static async fetchTranslationsListTimestamp() {
		let res = await Preferences.get({ key: "translationsListTimestamp" });
		return res?.value;
	}

	/**
	 * Saves the translations list timestamp in the preferences.
	 * @param {number} timestamp - The timestamp to be saved.
	 * @returns {Promise<void>} - A promise that resolves when the timestamp is saved.
	 */
	static async saveTranslationsListTimestamp(timestamp) {
		await Preferences.set({
			key: "translationsListTimestamp",
			value: JSON.stringify(timestamp),
		});
	}

	/**
	 * Fetches the timestamp of the locales list from the preferences.
	 * @returns {Promise<number>} The timestamp of the locales list.
	 */
	static async fetchLocalesListTimestamp() {
		let res = await Preferences.get({ key: "localesListTimestamp" });
		return res?.value;
	}

	/**
	 * Saves the timestamp of the locales list.
	 * @param {number} timestamp - The timestamp to be saved.
	 * @returns {Promise<void>} - A promise that resolves when the timestamp is saved.
	 */
	static async saveLocalesListTimestamp(timestamp) {
		await Preferences.set({
			key: "localesListTimestamp",
			value: JSON.stringify(timestamp),
		});
	}

	static async getAllBookmarks() {
		let allBookmarks = await Preferences.get({ key: `bookmarks` });
		allBookmarks = JSON.parse(allBookmarks.value ?? "{}");
		return allBookmarks;
	}

	static async getSuraBookmarks(sura, translation) {
		let allBookmarks = await PreferencesApi.getAllBookmarks();
		let suraTransBookmarks = allBookmarks[sura]?.[translation] ?? {};
		return suraTransBookmarks;
	}

	static async toggleAyaBookmark(sura, aya, translation, returnAll = false) {
		let allBookmarks = await PreferencesApi.getAllBookmarks();
		let suraTransBookmarks = allBookmarks[sura]?.[translation] ?? {};
		let ayaFound = suraTransBookmarks[aya];
		if (ayaFound) {
			delete suraTransBookmarks[aya];
		}
		else {
			suraTransBookmarks[aya] = new Date();
		}
		allBookmarks[sura] = allBookmarks[sura] ?? {};
		allBookmarks[sura][translation] = suraTransBookmarks;
		await Preferences.set({
			key: `bookmarks`,
			value: JSON.stringify(allBookmarks),
		});
		return returnAll ? allBookmarks :suraTransBookmarks;
	}

	static async clearAllBookmarks() {
		await Preferences.set({
			key: `bookmarks`,
			value: JSON.stringify({}),
		});
	}
}

export default PreferencesApi;
