import SuraFetcher from '../drivers/webApi/fetchers/SuraFetcher.js';
import TranslationFetcher from '../drivers/webApi/fetchers/TranslationFetcher.js';
import AyaFetcher from '../drivers/webApi/fetchers/AyaFetcher.js';
import ArabicShortTafsir from '../drivers/webApi/fetchers/ArabicTafsirFetcher.js';
import PreferencesApi from '../drivers/common/PreferencesApi.js';
import Axios from 'axios';
import AppConfigs from "../config/app.js";
import domtoimage from 'dom-to-image';
import {toArNumeral} from '../helpers/localization.js';

const service = {
    // setTranslationKey(vueRoot, translationKey) {
    //     console.log('setting translation key', translationKey);
    //     let translationKey = translationKey == 'english'? "english_saheeh": translationKey;
    //     vueRoot.translationDatabase = translationKey;
    // },
    connectToDatabases(vueRoot, translationKey, _cb) {
        vueRoot.arabicDatabase = null;
        vueRoot.translationDatabase = translationKey == 'english'? "english_saheeh": translationKey;
        // this.setTranslationKey(vueRoot, translationKey);
        _cb(true);
    },

    async getSuraContent(translationKey, suraNum) {
        let ayas = await SuraFetcher.fetchSuraContent(translationKey, suraNum);
        return ayas;
    },

    async getSingleAya(arabicDatabase, translationKey, ayaId, _cb) {
        let aya = await TranslationFetcher.getAyaTranslationbyId(translationKey, ayaId);
        _cb(aya);
    },

    async getSingleAyaByIndex(arabicDatabase, translationKey, suraNum, ayaNum) {
        let aya = await TranslationFetcher.getAyaTranslationByIndexes(translationKey, suraNum, ayaNum);
        return aya
    },

    async searchArabicContent(searchText, page, _cb) {
        let results = await AyaFetcher.search(searchText, page);
        
        if(typeof results == 'undefined' || ! results || results == "") {
            _cb({data: []});
            return;
        }

        _cb({data: results[0]});
    },

    async searchTranslations(searchText, page, translationKey, _cb) {
        let results = await TranslationFetcher.search(translationKey, searchText, page);
        if(typeof results == 'undefined' || ! results || results.length == 0) {
            _cb({data: []});
            return;
        }

        _cb({data: results[0]});
    },

    async changeTranslationDatabase(vueRoot, translationKey, _cb) {
        // this.setTranslationKey(vueRoot, translationKey);
        vueRoot.translationDatabase = translationKey == 'english'? "english_saheeh": translationKey;
        _cb(true);
    },

    async loadTafsir(tafsir, sura, aya, _cb) {
        let text =  await ArabicShortTafsir.fetch(tafsir.file, sura, aya);
        _cb(text);
    },

    async loadSuraOtherTranslations(suraId, requestedDatabases, currentDatabaseFile, _cb) {
        let records = [];
        for(let index in requestedDatabases) {
            let db = requestedDatabases[index];

            if(db.file == currentDatabaseFile) {
                continue;
            }
            let translations = await SuraFetcher.fetchSuraContent(db.file, suraId, false);
            // let translations = await this.retrieveBySura(suraId, db);

            records.push({
                language_iso_code: db.language_iso_code, 
                key: db.key, 
                file: db.file,
                translations: translations
            });
        }

        _cb(records);
    },

    async loadAyaOtherTranslations(suraNum, ayaNum, requestedDatabases, currentDatabaseFile, _cb) {
        let records = [];
        for(let index in requestedDatabases) {
            let db = requestedDatabases[index];

            if(db.file == currentDatabaseFile) {
                continue;
            }

            let translation = await TranslationFetcher.getAyaTranslationByIndexes(db.file, suraNum, ayaNum);

            records.push({
                language_iso_code: db.language_iso_code, 
                key: db.key, 
                file: db.file,
                translation: translation
            });
        }

        _cb(records);
    },

    async loadLocalesList() {
        let res = (
			await Axios.get(
				`${process.env.VUE_APP_API_BASE_URL}v1/translations/locales/list`
			)
		)?.data 

        res = res ?? AppConfigs.languages
        return res?.locales ?? AppConfigs.languages;
      },

    async updateLocalesList(currList) {
        //todo
        return null;        
    },
    
    async loadTranslationsList() {
		let res = (
			await Axios.get(
				`${process.env.VUE_APP_API_BASE_URL}v1/translations/list?with_database=1`
			)
		)?.data;

	    const translations = res?.translations ?? AppConfigs[
            process.env.VUE_APP_PLATFORM + "_translation_databases"
        ];
        
        return translations;
    },
    
    async updateTranslationsList(currList) {
        //todo
        return null;
    },

    async fetchLocale(locale) {
        // send api request to retrieve locale
        const downloadLink = `${process.env.VUE_APP_API_BASE_URL}v1/translations/locales/expressions?iso_code=` + locale; // fallback to the default download link
        const res = (await Axios.get(downloadLink));
        return res?.data;
    },

    async updateLocale(locale, messages) {
        // do nothing in web
        return null;
    },
    async getDownloadedTranslations() {
        if('caches' in window) {
            const keys= await caches?.keys() 
            const translations = keys.filter(key => key.startsWith('translation_')).map(translation => translation.replace('translation_', ''));
            return translations;
         } 
        
         return [];  
    }, 

    async downloadTranslation(trans) {
        const cache = await caches.open(`translation_${trans}`)
        const cacheList = [];
        for (let i = 1; i <= 114; i++) {
          cacheList.push(`${process.env.VUE_APP_API_BASE_URL}v1/translation/sura/${trans}/${i}`)
        }
        return await cache.addAll(cacheList);
    },

    async checkTranslationUpdate(translation) {
        return {updateAvailable: false} // we don't need to check for updates in web
    },

    async shareAsText(ayasData, suraName, translationName, shareUrl, copy=false) {
        const arabicText = ayasData.reduce((acc, aya) => {
            return acc + aya.arabic_text + `(${toArNumeral(aya.index)})\n`
        }
        , '');
        const translationText = translationName + ': \n' + ayasData.reduce((acc, aya) => {
            return acc + aya.translation + `(${aya.index})\n`
        }
        , '');
        let footnotes  = ayasData.reduce((acc, aya) => {
            return acc + aya.footnotes.trim();
        }
        , '')        
        const text  = [suraName, arabicText, translationText, footnotes, shareUrl].join('\n-------------------\n');
        
        if (copy) {
            await navigator.clipboard.writeText(text)
        } else {
            await navigator.share({
                title: 'Share Aya',
                text: text
            })
        }
    },

    async shareAsImage(ayasData, sura, translationName, shareUrl) {
        const arabic = ayasData.reduce((acc, aya) => {
            return acc + aya.arabic_text + ` ${toArNumeral(aya.index)}\n` }, '');
        const translation = ayasData.reduce((acc, aya) => {
            return acc + aya.translation + `(${aya.index})\n`
        }
        , '');

        let container = document.createElement('div');
        container.className = 'share-container';
        container.innerHTML = `
        <div class="share-template">
            <div class="share-sura-header">
                <img src="img/share/header.png" class="share-sura-bg" />
                <h1 class="share-sura-name">سورة ${sura}</h1>
            </div>
            <div class="share-arabic-text">
                ${arabic}
            </div>
            <div class="share-translation-header">
                <div class="share-translation-name">${translationName}</div>
                <div class="share-divider"></div>
            </div>
            <div class="share-translation-container" dir="auto">
                ${translation}
            </div>
            <img class="share-footer" src="img/share/footer.png"></img>
        </div>
        `
        const imageTemplate = container.querySelector('.share-template');
        // imageTemplate.style.transform = 'scale(0.1)'; // to make the image not affect the layout
        document.body.appendChild(container);
        const scale = 1;
        try { 
            const blob = await domtoimage.toBlob(
                imageTemplate, 
                {
                width: imageTemplate.clientWidth * scale,
                height: imageTemplate.clientHeight * scale,
                quality: 1,
                bgcolor: 'white',
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top center'
                }}
            );

            container.remove();
            await navigator.share({
                title: 'Share Aya',
                text: `Aya from QuranEnc - ${shareUrl}` ,
                files: [new File([blob], 'aya.png', {type: 'image/png'})]
            })
            window.plugins.socialsharing.share(null, null, url, null)
        } catch (error) {
            console.error("🚀 ~ share ~ error:", error)
        }
    },

    // ================== Bookmarks ==================
    async getSuraBookmarks(sura, translation) {
        return await PreferencesApi.getSuraBookmarks(+sura, translation);
    },
    async toggleBookmark(aya, sura, translation, returnAll = false) {
        return await PreferencesApi.toggleAyaBookmark(+sura, +aya, translation, returnAll);
    },
    async getAllBookmarks() {
        return await PreferencesApi.getAllBookmarks();
    }
    // ================================================
}
export default service;