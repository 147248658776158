<template>
  <div>
    

    <v-card>
      <v-card-subtitle class="px-1">
            <b>{{ $t("translation_databases." + currTrans) }}</b>
      </v-card-subtitle>      
      <v-card-title class="align-center">
        <small>{{ $t("browse_sowar") }}</small>
        <v-spacer></v-spacer>
        <v-text-field
          v-ripple="{ class: 'primary--text' }"
          hide-details
          :placeholder="$t('search')"
          outlined
          class="rounded-md mt-2"
          single-line
          dense
          prepend-inner-icon="mdi-magnify"
          style="max-width: 200px"
          v-model="filterText"
        ></v-text-field>
      </v-card-title>
      
      <v-card-text>
        <transition-group name="fadeDown" tag="div">
          <template v-for="(sura, index) in filteredSuras">
            <v-card
              :key="'sura-' + index"
              class="mb-5 custom-card-shadow rounded-lg"
              v-ripple="{ class: 'primary--text' }"
              @click="goToSura(sura.id)"
            >
              <v-card-title class="fs-sm">
                <span class="fs-xs grey--text text--darken-1">{{ sura.id }}. {{ sura.title }}</span>
                <v-spacer></v-spacer>
                <v-btn text small class="font-weight-regular" color="primary">
                  <v-icon small class="me-3">
                    mdi-eye-outline
                  </v-icon>
                  {{ $t("browse_trans") }}
                </v-btn>
              </v-card-title>
            </v-card>
          </template>
        </transition-group>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    suras: [],
    showFilter: false,
    filterText: "",
  }),
  created(){
  },
  mounted() {
    this.$root.goBack = ()=>{
        this.goHome();
    }    
    window.scrollTo(0, 0);
    this.assignSuras();
    //console.log(this.$route)
  },
  computed: {
    filteredSuras() {
      const ft = this.filterText.toLowerCase();
      if (ft) {
        return this.suras.filter((sura) => sura.title.toLowerCase().includes(ft));
      } else {
        return this.suras;
      }
    },
    currTrans() {
      return this.$route.query.trans;
    },
  },
  methods: {
    assignSuras() {
      // optimized for perfomance
      let suras = [];
      for (let i = 1; i <= 114; i++) {
        let title =
          this.$root.locale == "ar"
            ? this.$root.QuranData["Sura"][i][4]
            : this.$root.QuranData["SuraTitles"][i] +
              " - " +
              this.$root.QuranData["Sura"][i][4];
        suras.push({ id: i, title: title });
      }
      this.suras = suras;
    },
    goHome(){
      this.$router.push({ name: "home" });
    },
    goToSura(suraId) {
      let trans = this.$route.query.trans;
      if (!trans) return;
      this.$root.nuxt.showLoading = true;
      this.$router.push({
        name: "show_sura",
        params: {
          suraId: suraId,
        },
        query: {
          lang: this.$root.locale,
          trans: trans,
        },
      });
    },
  },

  watch: {
    "$root.locale"(val) {
      this.assignSuras();
    },
  },
};
</script>
