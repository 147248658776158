<template>
  <v-dialog v-model="dialog" scrollable max-width="620px" eager>
    <v-card class="rounded-lg wide custom-card-shadow pb-5">
      <v-card-title class="font-weight-medium">
        {{ $t("view_other_transes") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        
        {{ selectedTranslations.length }} - {{ maxSelection }}
        
      </v-card-subtitle>
      <v-card-text class="px-5">
        <template
          v-for="(translations, langKey, index) in filteredTranslations"
        >
          <v-list :key="langKey" dense>
            <v-subheader class="thirdly--text px-5">
              {{ isoCodesObj[langKey] || "...." }}
            </v-subheader>
            <template v-for="(translation, tIndex) in translations">
              <v-list-item
                :key="`trans-${translation.key}`"
                class="transparent"
                :disabled="(isWeb || isDownloaded(translation.key)) && 
                  (!isSelected(translation) &&
                    selectedTranslations.length === maxSelection)
                  "
              >
                <v-list-item-icon class="me-4"
                  :disabled="(isWeb || isDownloaded(translation.key)) && 
                  (!isSelected(translation) &&
                    selectedTranslations.length === maxSelection)
                  "
                  @click="(isWeb || isDownloaded(translation.key)) ? handleClick(translation) : download(translation.key)"
                  >
                  <v-fade-transition hide-on-leave group>
                    <v-icon
                      key="checked-icon"
                      v-if="isSelected(translation)"
                      color="primary"
                    >
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon
                      key="unchecked-icon"
                      v-else-if="isWeb || isDownloaded(translation.key)"
                      color="grey lighten-1"
                    >
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon
                      key="download-icon"
                      v-else
                      :color="isLoading(translation.key) ? 'grey' : 'primary'"
                    >
                    {{ isLoading(translation.key) ? 'mdi-loading' : 'mdi-cloud-download-outline' }}
                  </v-icon>
                  </v-fade-transition>
                </v-list-item-icon>
                <v-list-item-content class="py-0" 
                  :disabled="
                    !isSelected(translation) &&
                      selectedTranslations.length === maxSelection
                    "
                  @click="handleClick(translation)"
                >
                  <v-list-item-title
                    class="primary--text font-weight-regular"
                  >
                    {{
                      $t("translation_databases." + translation.key)
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="success darken-1" text @click="apply"><v-icon>mdi-check</v-icon></v-btn>
        <v-btn outlined color="info darken-1" text @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../event-bus.js";

export default {
  props: ["showOtherTranslationsDialog","currTrans"],

  data() {
    return {
      selectedTranslations: [],
      lenErr: false,
      searchQuery: "",
      maxSelection: 2,
      loadings: [],
      downloaded: [],
    };
  },

  async mounted() {
    this.downloaded = await this.getDownloadedList() ?? [];
  },

  methods: {
    async getDownloadedList() {
      let downloaded = await this.$service.getDownloadedTranslations();
      return downloaded ?? [];
    },
    isLoading(trans) {
      return this.loadings.indexOf(trans) != -1;
    },
    async download(trans) {
      try { 
        if (this.loadings.indexOf(trans) != -1) return;

        this.loadings.push(trans);

        await this.$service.downloadTranslation(trans);

        this.downloaded.push(trans);
      } catch (error) {
        alert(error?.message || this.$t("error"));
      } finally{
        this.loadings = this.loadings.filter((item) => item !== trans);
      }
    },

    isDownloaded(trans) {
      return this.downloaded && this.downloaded.indexOf(trans) != -1;
    },

    isSelected(translation) {
      return this.selectedTranslations.some((item) => {
        return item.file == translation.file;
      });
    },

    itemClass(translation) {
      return this.isSelected(translation)
        ? "selected_translation"
        : "unselected_translation";
    },

    async handleClick(translation) {

      if (!this.isWeb && !this.isDownloaded(translation.key)) 
        await this.download(translation.key);


      if (this.isSelected(translation)) {
        return this.unselectTranslation(translation);
      }

      return this.selectTranslation(translation);
    },

    unselectTranslation(translation) {
      this.lenErr = false;
      let result = this.selectedTranslations.filter((item) => {
        return item.file != translation.file;
      });

      this.selectedTranslations = result;
    },

    selectTranslation(translation) {
      if (this.selectedTranslations.length >= this.maxSelection) {
        this.lenErr = true;
      } else {
        this.lenErr = false;
        this.selectedTranslations.push(translation);
      }
    },

    apply() {
      this.$root.otherTranslations = this.selectedTranslations;
      //console.log(this.$root.otherTranslations);
      EventBus.$emit("other-translations-changed");
      this.dialog = false;
    },
  },

  computed: {
    dialog: {
      set(val) {
        this.$emit("update:showOtherTranslationsDialog", val);
      },
      get() {
        return this.showOtherTranslationsDialog;
      },
    },
    filteredTranslations() {
      const translations = this.$root.translationsList.filter(
        (trans) => {
          return trans.file != this.currTrans;
        }
      );
      const searchQuery = this.searchQuery.toLowerCase();
      const filtered = translations.filter(
        (translation) =>
          !searchQuery ||
          this.$t(`translation_databases.${translation.key}`)
            .toLowerCase()
            .includes(searchQuery)
      );
      const grouped = {};
      for (let i = 0; i < filtered.length; i++) {
        const el = filtered[i];
        const code = this.isoCodesObj[el.language_iso_code]
          ? el.language_iso_code
          : "other";
        if (!grouped[code]) {
          grouped[code] = [];
        }
        grouped[code].push(el);
      }
      const others = { ...grouped.other };
      delete grouped.other;
      grouped.other = others;
      return grouped;
    },
    isWeb() {
      return this.$platform === "web";
    },
    isoCodesObj() {
      let obj = {};
      const isos = this.$root.isoCodes || {};
      for (let i in isos) {
        obj[i] = isos[i]?.native;
      }
      return obj;
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.selectedTranslations = this.$root.otherTranslations;
        let query = { ...this.$route.query, ...{ d: "1" } };
        this.$router
          .push({
            query: query,
          })
          .catch(() => {});
      } else {
        if (!this.$route.query.d) return;
        history.go(-1);
      }
    },
    "$route.query.d"(val) {
      if (!val) this.dialog = false;
    },
  },
};
</script>

<style scoped>
.selection_title {
  cursor: pointer;
}

.selected_translation {
  background-color: #ddd;
}

.unselected_translation {
  color: blue;
}
</style>
