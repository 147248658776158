import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)


const i18n =  new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: function  () {
    const locales = require.context('../public/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
    return messages
  }(),
})

export default i18n

export async function loadCurrentLocale(root, localeToLoad, prevLocale = null) {
  // Get the current locale
  try { 
    const messages = await root.$service.fetchLocale(localeToLoad)
    i18n.setLocaleMessage(localeToLoad, messages)
    const updated = await root.$service.updateLocale(localeToLoad, messages)
    updated && i18n.setLocaleMessage(localeToLoad, updated)
  } catch (e) {
      throw e
  }
}
