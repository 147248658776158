<template>
  <div>
  <v-dialog v-model="dialog" scrollable max-width="600px">
    <v-card class="wide custom-card-shadow">
      <v-card-title>
        {{ $t("send_note") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-text-field
          filled
          rounded
          class="rounded-sm"
          :label="$t('name')"
          v-model="name"
        ></v-text-field>
        <v-text-field
          filled
          rounded
          class="rounded-sm"
          :label="$t('email')"
          v-model="email"
        ></v-text-field>
        <v-textarea
          filled
          rounded
          rows="3"
          auto-grow
          class="rounded-sm"
          :error="validationError"
          :label="$t('note')"
          v-model="note"
        ></v-textarea>
        <v-textarea
          filled
          rounded
          rows="3"
          auto-grow
          class="rounded-sm"
          :label="`${$t('suggested_trans')} ( ${$t('optional')} )`"
          v-model="suggested_trans"
        ></v-textarea>
      </v-card-text>
      <v-card-actions class="pb-4 px-md-14">
        <v-spacer></v-spacer>
        <v-btn
          large
          class="px-10 rounded-sm border-sm"
          outlined
          color="primary"
          @click="dialog = false"
        >
          {{ $t("close") }}
        </v-btn>
        <v-btn
          color="primary"
          large
          class="rounded-sm px-10"
          @click="saveNote()"
        >
          {{ $t("submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    
  </v-dialog>
  <v-snackbar
      v-model="snackbar"
      bottom
      center
      color="primary darken-4"
      :timeout="3000"
    >
      {{ $t("done") }}
      <v-btn icon @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["showDialog", "sura", "aya", "trans", "locale"],
  data() {
    return {
      name: "",
      email: "",
      note: "",
      suggested_trans: "",
      validationError: false,
      snackbar: false,
    };
  },
  mounted() {
    this.name = window.localStorage.getItem("name") || "";
    this.email = window.localStorage.getItem("email") || "";
  },
  computed: {
    dialog: {
      set(val) {
        this.$emit("update:showDialog", val);
      },
      get() {
        return this.showDialog;
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        let query = { ...this.$route.query, ...{ d: "1" } };
        this.$router
          .push({
            query: query,
          })
          .catch(() => {});
      } else {
        if (!this.$route.query.d) return;
        history.go(-1);
      }
    },
    "$route.query.d"(val) {
      if (!val) this.dialog = false;
    },
  },
  methods: {
    saveNote() {
      this.validationError = false;
      if (this.note.length < 3) {
        this.validationError = true;
        return;
      }

      window.localStorage.setItem("name", this.name);
      window.localStorage.setItem("email", this.email);
      const sendNoteURL =
        "https://quranenc.com/" + this.locale + "/ajax/note/add";

      axios
        .post(sendNoteURL, {
          sura: this.sura,
          aya: this.aya,
          interface: this.locale,
          trans_key: this.trans,
          note_name: this.name ? this.name : "",
          note_email: this.email ? this.email : "",
          note_txt: this.note,
          suggested_trans: this.suggested_trans,
        })
        .then((response) => {
          this.snackbar = true;
          this.dialog = false;
          this.note = "";
          this.suggested_trans = "";
        })
        .catch((error) => {
          alert(this.$t("failed"));
        });
    },

    validateNote() {
      if (!this.note) {
        this.validationError = this.$t("note");
        return false;
      }
      return true;
      if (this.email && !this.validateEmail(this.email)) {
        this.validationError = this.$root.locale["Err_email"];
        return false;
      }

      this.validationError = "";
      return true;
    },

    validateEmail(email) {
      //let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //return re.test(email);
    },
  },
};
</script>
