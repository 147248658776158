<template>
  <div>
    <div class="d-flex justify-center pt-6 pb-3">
      <v-avatar size="100">
        <img class="whiten-logo" src="../assets/app_logo_new.png" />
      </v-avatar>
    </div>
    
    <div class="text-center fs-xs grey--text text--lighten-2">{{this.$t("title")}}</div>

    <v-list>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        class="ps-10"
        @click="goToPage(item)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="fs-xs">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {


  computed: {
    items() {

      
    return [{
          title: this.$t("home"),
          route: "home",
          icon: "mdi-home",
        },
        {
          title: this.$t("bookmarks"),
          route: "bookmarks",
          icon: "mdi-bookmark",
        },
        {
          title: this.$t("about"),
          route: "https://quranenc.com/" + this.$root.locale + "/home/about/",
          icon: "mdi-information-outline",
        },

        {
          title: this.$t("contact_us"),
          route: "mailto:info@quranenc.com",
          icon: "mdi-email-outline",
        },
        {
          title: "QuranEnc.com",
          route: "https://quranenc.com/" + this.$root.locale,
          icon: "mdi-open-in-new",
        },
        {
          title: "Developers API",
          route: "https://quranenc.com/" + this.$root.locale + "/home/api/",
          icon: "mdi-code-braces",
        }
      ];
    },
  },

  methods: {  
    goToPage(item) {
      if (
        item.route.indexOf("http") != -1 ||
        item.route.indexOf("mailto") != -1
      ) {
        window.open(item.route);
        return;
      }

      this.$router.push({
        name: item.route,
        query: { lang: this.$root.locale },
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.whiten-logo {
  filter: brightness(0) invert(1);
}
</style>
