<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="600px">
      <v-card class="wide custom-card-shadow">
        <v-form @submit.prevent="submitPageNumber()" ref="pageForm">
          <v-card-title>
            {{ $t("page_num") }}
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-text-field filled rounded class="rounded-sm" min="1" type="number" max="604" single-line dense
              :label="$t('page_num')" v-model="currPage" placeholder="1-604"
              :rules="[v => !!v || $t('required'), v => (v && v >= 1 && v <= 604) || '1 - 604']"></v-text-field>
          </v-card-text>
          <v-card-actions class="pb-4 px-md-14">
            <v-spacer></v-spacer>
            <v-btn large class="px-10 rounded-sm border-sm" outlined color="primary" @click="dialog = false">
              {{ $t("close") }}
            </v-btn>
            <v-btn color="primary" large class="rounded-sm px-10" @click="submitPageNumber()" type="submit">
              {{ $t("submit") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>

export default {
  props: ["showDialog", "currPage"],
  computed: {
    dialog: {
      set(val) {
        this.$emit("update:showDialog", val);
      },
      get() {
        return this.showDialog;
      },
    },
  },

  methods: {
    submitPageNumber() {
      if (!this.$refs.pageForm.validate()) return;
      this.dialog = false;
      this.$emit("submit", this.currPage);
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        let query = { ...this.$route.query, ...{ d: "1" } };
        this.$router
          .push({
            query: query,
          })
          .catch(() => { });
      } else {
        if (!this.$route.query.d) return;
        history.go(-1);
      }
    },
    "$route.query.d"(val) {
      if (!val) this.dialog = false;
    },
  },
};

</script>
